import React from 'react'
import styles from './PromotionalBanner.module.css'
const PromotionalBanner = ({bannerSrc, handleClick}) => {
  return (
    <div className={styles.promotionalBanner}>
      <img src={bannerSrc} alt="promotional banner" onClick={handleClick} />
    </div>
  )
}

export default PromotionalBanner