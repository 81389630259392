import React, { useContext, useState, useEffect, useRef } from 'react'
import Modal from 'react-bootstrap/Modal';
import './ReferModal.css'
import '../../../assets/css/modal.css'
import { baseUrl, dataContext, portal } from '../../../App';
import activityLogSubmission from '../../../helper/activitylog';
import analyticEvent from '../../../helper/gaEvent';
import Spinner from "react-bootstrap/Spinner";
import axios from 'axios';
import { FaFacebookSquare } from "react-icons/fa";
import { FaXTwitter, FaFacebookF, FaCommentSms, FaWhatsapp, FaTelegram, FaFacebookMessenger, FaCopy } from "react-icons/fa6";
import clevertap from 'clevertap-web-sdk';
const ReferModal = ({ showReferModal, setShowReferModal }) => {
  const { token, setToken, playerData, setPlayerData } = useContext(dataContext);
  const [mobileNumber, setMobileNumber] = useState("");
  const [showErrorOutline, setShowErrorOutline] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const timeoutRef = useRef(null); // To keep track of the timeout

  const handleClick = () => {
    // Clear any previous timeout
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    // Show the tooltip
    setShowTooltip(true);

    // Set a new timeout to hide the tooltip after 1000ms
    timeoutRef.current = setTimeout(() => {
      setShowTooltip(false);
    }, 400);
  };

  // Clear the timeout if the component unmounts to avoid memory leaks
  useEffect(() => {
    // clevertap.event.push("Home viewed");
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);
  const tooltipStyle = {
    visibility: "visible",
    backgroundColor: "black",
    color: "#fff",
    textAlign: "center",
    borderRadius: "5px",
    padding: "5px 10px",
    position: "absolute",
    zIndex: 1,
    bottom: "125%", // Position above the button
    left: "50%",
    transform: "translateX(-50%)",
    opacity: 1,
    transition: "opacity 0.3s"
  };

  function hideModalOperation() {
    setShowReferModal(false)
  }
  function shareSocial(siteName) {
    const urlToShare = `https://quizard.live/?refer_code=${playerData?.code}`;  // Replace with your URL

    const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(urlToShare)}`;
    const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(urlToShare)}`;
    const messengerUrl = `fb-messenger://share?link=${encodeURIComponent(urlToShare)}`;
    const twitterUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(`Check out this link: `)}&url=${encodeURIComponent(urlToShare)}`;
    const telegramUrl = `https://t.me/share/url?url=${encodeURIComponent(urlToShare)}&text=${encodeURIComponent('Check this out!')}`;
    const message = `Check this out: ${urlToShare}`;
    const smsUrl = `sms:?&body=${encodeURIComponent(message)}`;

    if (siteName == 'fb') { window.open(facebookShareUrl) }
    if (siteName == 'wp') { window.open(whatsappUrl) }
    if (siteName == 'msngr') { window.open(messengerUrl) }
    if (siteName == 'twt') { window.open(twitterUrl) }
    if (siteName == 'tlg') { window.open(telegramUrl) }
    if (siteName == 'sms') { window.open(smsUrl) }
  }

  function checkMobileNumber() {
    var mobilePattern = /^(?:\+88|88)?(01[3-9]\d{8})$/;
    if (mobileNumber.match(mobilePattern)) {
      return true;
    } else {
      return false;
    }
  }
  function loginOperation() {
    if (checkMobileNumber()) {
      setIsLoading(true);
      axios
        .post(`${baseUrl}/api/login/`, {
          msisdn: mobileNumber.toString().slice(-10),
          portal: portal,
          qcid: sessionStorage.getItem("cid")
        })
        .then((response) => {
          // console.log(response.data.token);
          let newToken = response.data.token;
          localStorage.setItem("AuthToken", newToken);
          localStorage.setItem("user_msisdn", mobileNumber.toString().slice(-10));
          setToken(newToken);
          activityLogSubmission(
            "login_submit",
            mobileNumber.toString().slice(-10)
          );
          analyticEvent('login', 'login-success')
          setIsLoading(false);
        });
    } else {
      console.log("error");
      setShowErrorOutline(true);
      analyticEvent('login', 'login-error')
    }
  }


  return (
    <>
      <Modal show={showReferModal} centered dialogClassName="modal-dialog" contentClassName="modalContent" onHide={hideModalOperation}>
        <Modal.Header >
          <Modal.Title>রেফার করুন</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <Spinner animation="border" className='loadingSpinner' /> */}
          {(!playerData?.id) && <><span className={`inputSpan ${showErrorOutline && "errorOutline"}`}>
            +88{" "}
            <input
              type="tel"
              maxLength={11}
              onKeyUp={(e) => {
                setMobileNumber(e.target.value);
                setShowErrorOutline(false);
              }}
              onKeyDown={(e) => e.key === 'Enter' && loginOperation()}
              id="mobileNumber"
              name="mobileNumber"
              placeholder="আপনার মোবাইল নম্বর"
              className="inputFieldMobile"
            />{" "}
          </span>
            <button
              type="button"
              className="btn numberSubmit"
              onClick={loginOperation}
              style={{ marginBottom: '10px' }}
            >
              {isLoading ? (
                <Spinner animation="border" variant="light" size="sm" />
              ) : (
                "GET CODE"
              )}
            </button>
          </>}
          <div>
            শেয়ার করুন, ৫০% ক্যাশব্যাক জিতুন
          </div>
          <div style={{ marginTop: '10px' }}>
            আপনার কোড: - {playerData?.code && <span className='copyCodeSpan' style={{ fontWeight: '600',position:'relative',display:'inline-block' }} onClick={() => { navigator.clipboard.writeText(playerData?.code); handleClick(); }}>{playerData?.code} <FaCopy />
              {showTooltip && (
                <div style={tooltipStyle}>Copied</div>
              )}
            </span>}
          </div>
          <div className={`shareContainer ${playerData?.id ? '' : 'disabled'}`}>
            <div className="socialBox" style={{ color: '#3b5998' }} onClick={() => { shareSocial('fb') }} ><FaFacebookF /></div>
            <div className="socialBox" style={{ color: '#000000' }} onClick={() => { shareSocial('twt') }}><FaXTwitter /></div>
            <div className="socialBox" style={{ color: '#3b5998' }} onClick={() => { shareSocial('sms') }}><FaCommentSms /></div>
            <div className="socialBox" style={{ color: '#25D366' }} onClick={() => { shareSocial('wp') }}><FaWhatsapp /></div>
            <div className="socialBox" style={{ color: '#24A1DE' }} onClick={() => { shareSocial('tlg') }}><FaTelegram /></div>
            <div className="socialBox" onClick={() => { shareSocial('msngr') }}><FaFacebookMessenger className="messenger-icon" /></div>
          </div>
          <div className='termsInReferral'>
            *শর্ত প্রযোজ্য
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default ReferModal