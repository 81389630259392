import React, { useContext } from 'react'
import styles from './Bottombar.module.css'
import { dataContext } from '../../App';

// Import all images
import homeImg from './assets/home.svg'
import selectedHomeImg from './assets/selectedHome.svg'
import leaderImg from './assets/leaderboard.svg'
import selectedLeaderImg from './assets/selectedLeaderboard.svg'
import profileImg from './assets/profile.svg'
import selectedProfileImg from './assets/selectedProfile.svg'
import winnerImg from './assets/winner.svg'
import selectedWinnerImg from './assets/selectedWinner.svg'
import categoryImg from './assets/category.svg'
import selectedCategoryImg from './assets/selectedCategory.svg'
import khelunImg from './assets/khelun.svg'

// Navigation item configuration
const NAV_ITEMS = [
  {
    id: 'home',
    label: 'হোম',
    icon: homeImg,
    selectedIcon: selectedHomeImg,
  },
  {
    id: 'category',
    label: 'ক্যাটাগরি',
    icon: categoryImg,
    selectedIcon: selectedCategoryImg,
  },
  {
    id: 'khelun',
    label: '',
    icon: khelunImg,
    isSpecial: true,
  },
  {
    id: 'leader',
    label: 'লিডারবোর্ড',
    icon: leaderImg,
    selectedIcon: selectedLeaderImg,
  },
  {
    id: 'profile',
    label: 'প্রোফাইল',
    icon: profileImg,
    selectedIcon: selectedProfileImg,
    requiresAuth: true,
  },
];

const NavItem = ({ item, isActive, onClick, setShowShobDekho }) => {
  const icon = isActive && item.selectedIcon ? item.selectedIcon : item.icon;

  if (item.isSpecial) {
    return (
      <div className={styles.khelunImg} onClick={() => setShowShobDekho(true)}>
        <img src={icon} alt={item.label} />
      </div>
    );
  }

  return (
    <div
      className={`${styles.nav_item} ${isActive ? styles.active : ''}`}
      onClick={onClick}
    >
      <img src={icon} alt={item.label} />
      {item.label && <span>{item.label}</span>}
    </div>
  );
};

const Bottombar = ({ activeBottom, setActiveBottom, setShowLoginModal, setShowShobDekho }) => {
  const { playerData } = useContext(dataContext);

  const handleNavClick = (item) => {
    if (item.requiresAuth && !playerData?.id) {
      setShowLoginModal(true);
    } else {
      setActiveBottom(item.id);
  }
};

return (
  <div className={styles.ppm_bottom_bar}>
    <div className={styles.bottom_nav}>
      {NAV_ITEMS.map(item => (
        <NavItem
          key={item.id}
          item={item}
          setShowShobDekho={setShowShobDekho}
          isActive={activeBottom === item.id}
          onClick={() => handleNavClick(item)}
        />
      ))}
    </div>
  </div>
);
};

export default Bottombar;