import clevertap from 'clevertap-web-sdk';
function handleCleverTapEventPushClick(eventName, description) {
  clevertap.event.push(eventName, {
    ...description,
    "Date": new Date()
  }); // Replace Payload as per your event schema and design
}

function pushCleverTapEvent(eventName) {
  // clevertap.event.push(eventName);
  console.log(eventName);
}

export { handleCleverTapEventPushClick, pushCleverTapEvent }