import React, { useState, useEffect, useContext } from 'react';
import styles from './ReferralHistory.module.css';
import referralData from './dummy_data.json';
import { formatDate } from './dateFormatter';
import { convertToBengaliNumber } from '../../BannerPart3/helper';
import { dataContext } from '../../../App';
import { fetchReferralHistory } from '../../../services';

const ReferralHistory = () => {
  const {token} = useContext(dataContext);
  const [data, setData] = useState(null);

  useEffect(() => {
    fetchReferralHistory(token).then((res) => {
      console.log(res);
      setData(res);
    });
  }, [token]);

  const getStatusText = (status) => {
    switch (status) {
      case 'accepted':
        return 'একসেপ্টেড';
      case 'pending':
        return 'পেন্ডিং';
      default:
        return 'এনরোলমেন্ট';
    }
  };

  if (!data) {
    return <div>Loading...</div>;
  }

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>রিসেন্ট রেফারেল</h2>

      <div className={styles.totalAmount}>
        <span className={styles.totalLabel}>টোটাল রেফারেল আর্নিং:</span>
        <span className={styles.amount}>৳{convertToBengaliNumber(data.total_referral_amount)}</span>
      </div>

      {(data?.data.length > 0) && <div className={styles.referralList}>
        {[...data.data].reverse().map((referral, index) => (
          <div key={index} className={styles.referralItem}>
            <div className={styles.userInfo}>
              <img 
                src={`https://ms.purplepatch.online/bkash-quiz-banner/avatar/1.png`} 
                alt={`Avatar ${referral.avatarId}`}
                className={styles.avatar}
              />
              <div className={styles.details}>
                <div className={styles.playerName}>{ `0${referral?.msisdn.slice(0,3)}****${referral?.msisdn.slice(-3)}`}</div>
                <div className={styles.enrollmentDate}>
                  {getStatusText(referral.referralStatus)}, {formatDate(referral.created_at)}
                </div>
              </div>
            </div>
            <div className={styles.referralAmount}>৳{convertToBengaliNumber(referral.referral_amount)}</div>
          </div>
        ))}
      </div>}
    </div>
  );
};

export default ReferralHistory;
