import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { dataContext } from "../../App";
import logo from "./logo192.png";
import LoginModalCampaign from "../../components/LoginModalCampaign/LoginModalCampaign";
import activityLogSubmission from "../../helper/activitylog";
import styles from "./Campaign5.module.css";
import LoginDivGreen from "../../components/LoginDivOnly/LoginDivGreen";
const Campaign5 = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeBottom, setActiveBottom] = useState("campaign");
  const cid = searchParams.get("qcid");
  const { playerData } = useContext(dataContext);
  let navigate = useNavigate();
  if (!cid) {
    navigate("../");
  }
  useEffect(() => {
    let campaignId = null;
    if (searchParams.get('qcid')) {
      sessionStorage.setItem('cid', searchParams.get('qcid'));
      campaignId = searchParams.get('qcid');
    }

    let msisdn = null;
    if (playerData?.id) {
      msisdn = playerData?.msisdn;
    }
    activityLogSubmission("campaign", msisdn);
    activityLogSubmission("campaign5", msisdn);
  }, []);

  return (
    <>
      <div className={`${styles.landingPage}`}>
        <img src={logo} alt="" style={{width: "26%", position: "absolute", top: "50%", left: "37%", transform: "translateY(-255%)"}} />
        <LoginDivGreen  activeBottom={activeBottom} setActiveBottom={setActiveBottom}/>

        <div className={styles.content} style={{position: "absolute", bottom: "2%", padding:'24px', fontSize: "14px", color: "#232323", fontWeight: "500", textAlign:'justify'}}>
          <p>
            Quizard is a subscription-based service. Price for user 30BDT/week. Users can unsubscribe from the service any time to click on unsubscribe button.
            <div style={{textDecoration: "underline", cursor: "pointer", display:'flex', justifyContent:'center', alignItems:'center', marginTop:'12px'}} 
            onClick={()=>{navigate(`../?page=tc&qcid=${cid}`);}}>Terms & Conditions</div>
          </p>
        </div>
      </div>
    </>
  );
};

export default Campaign5;
