import React, { useContext, useEffect, useRef, useState } from 'react'
import styles from './Referral.module.css'
import './referral.css'
import { baseUrl, dataContext, portal } from '../../App';
import { TiArrowBackOutline } from "react-icons/ti";
import Spinner from "react-bootstrap/Spinner";
import axios from 'axios';
import { FaXTwitter, FaFacebookF, FaCommentSms, FaWhatsapp, FaTelegram, FaFacebookMessenger, FaCopy } from "react-icons/fa6";
import clevertap from 'clevertap-web-sdk';
import activityLogSubmission from '../../helper/activitylog';
import analyticEvent from '../../helper/gaEvent';
import whatsappIcon from './icons/1.png'
import twitterIcon from './icons/6.png'
import telegramIcon from './icons/4.png'
import facebookIcon from './icons/5.png'
import messengerIcon from './icons/2.png'
import messageIcon from './icons/3.png'
import ReferralHistory from '../Profile/ReferralHistory/ReferralHistory';
const Referral = ({props}) => {
  const { token, setToken, playerData, setPlayerData, events, setEvents } = useContext(dataContext);
  const { setActiveBottom } = props;

  const [mobileNumber, setMobileNumber] = useState("");
  const [showErrorOutline, setShowErrorOutline] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const timeoutRef = useRef(null); //

  const handleClick = () => {
    // Clear any previous timeout
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    // Show the tooltip
    setShowTooltip(true);

    // Set a new timeout to hide the tooltip after 1000ms
    timeoutRef.current = setTimeout(() => {
      setShowTooltip(false);
    }, 400);
  };

  // Clear the timeout if the component unmounts to avoid memory leaks
  useEffect(() => {
    activityLogSubmission("refer");
    // clevertap.event.push("Referral Offer Viewed");
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);
  const tooltipStyle = {
    visibility: "visible",
    backgroundColor: "black",
    color: "#fff",
    textAlign: "center",
    borderRadius: "5px",
    padding: "5px 10px",
    position: "absolute",
    zIndex: 1,
    bottom: "125%", // Position above the button
    left: "50%",
    transform: "translateX(-50%)",
    opacity: 1,
    transition: "opacity 0.3s"
  };

  function shareSocial(siteName) {
    const urlToShare = `https://quizard.live/?refer_code=${playerData?.code}`;  // Replace with your URL
    let urlWithText = `Congratulations! আপনি জিতে নিয়েছেন ১০ টাকা ক্যাশ ইন! প্রথম সাবস্ক্রিপশনের সাথে সাথেই পাচ্ছেন ১০ টাকার ক্যাশ ইন। আপনার রেফার কোড: ${playerData?.code}। ক্যাশ ইন পেতে নিচের লিঙ্কে ক্লিক করুন:
${urlToShare}
`;
    const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(urlToShare)}`;
    const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(urlWithText)}`;
    const messengerUrl = `fb-messenger://share?link=${encodeURIComponent(urlToShare)}`;
    const twitterUrl = `https://x.com/intent/tweet?text=${encodeURIComponent(`Congratulations! আপনি জিতে নিয়েছেন ১০ টাকা ক্যাশ ইন! প্রথম সাবস্ক্রিপশনের সাথে সাথেই পাচ্ছেন ১০ টাকার ক্যাশ ইন। আপনার রেফার কোড: ${playerData?.code}। ক্যাশ ইন পেতে নিচের লিঙ্কে ক্লিক করুন: `)}&url=${encodeURIComponent(urlToShare)}`;
    const telegramUrl = `https://t.me/share/url?url=${encodeURIComponent(urlToShare)}&text=${encodeURIComponent(`Congratulations! আপনি জিতে নিয়েছেন ১০ টাকা ক্যাশ ইন! প্রথম সাবস্ক্রিপশনের সাথে সাথেই পাচ্ছেন ১০ টাকার ক্যাশ ইন। আপনার রেফার কোড: ${playerData?.code}। ক্যাশ ইন পেতে লিঙ্কে ক্লিক করুন।`)}`;
    const smsUrl = `sms:?&body=${encodeURIComponent(urlWithText)}`;

    if (siteName == 'fb') { window.open(facebookShareUrl) }
    if (siteName == 'wp') { window.open(whatsappUrl) }
    if (siteName == 'msngr') { window.open(messengerUrl) }
    if (siteName == 'twt') { window.open(twitterUrl) }
    if (siteName == 'tlg') { window.open(telegramUrl) }
    if (siteName == 'sms') { window.open(smsUrl) }
  }

  function checkMobileNumber() {
    var mobilePattern = /^(?:\+88|88)?(01[3-9]\d{8})$/;
    if (mobileNumber.match(mobilePattern)) {
      return true;
    } else {
      return false;
    }
  }
  function loginOperation() {
    if (checkMobileNumber()) {
      setIsLoading(true);
      axios
        .post(`${baseUrl}/api/login/`, {
          msisdn: mobileNumber.toString().slice(-10),
          portal: portal,
          qcid: "16"
        })
        .then((response) => {
          // console.log(response.data.token);
          let newToken = response.data.token;
          localStorage.setItem("AuthToken", newToken);
          localStorage.setItem("user_msisdn", mobileNumber.toString().slice(-10));
          setToken(newToken);
          activityLogSubmission(
            "login_submit",
            mobileNumber.toString().slice(-10)
          );
          analyticEvent('login', 'login-success')
          setIsLoading(false);
        });
    } else {
      console.log("error");
      setShowErrorOutline(true);
      analyticEvent('login', 'login-error')
    }
  }


  return (
    <React.Fragment>
      <div className={styles['referral-container']}>
        <div className={styles['referral-top']}>
          <div className={styles['referral-top-content']}>
          রেফার কোড ব্যবহারে ১০ টাকা ক্যাশ ইন 
          </div>
          <div className={styles['referral-top-description']}>আপনার রেফার কোড ব্যবহার করে কেউ যদি সাবস্ক্রিপশন করে, তাহলে প্রতি সফল সাবস্ক্রিপশনে আপনি পেয়ে যাবেন ১০ টাকা ক্যাশ ইন । কারো শেয়ারকৃত রেফার কোড ব্যাবহার করে সাবস্ক্রিপশন করলে, ১ম সাবস্ক্রিপশনে আপনি পেয়ে যাবেন ১০ টাকার ক্যাশ ব্যাক ।</div>
          <div className={styles['refferral-login-container']}>
          {(!playerData?.id) && <><span className={`inputSpan ${showErrorOutline && "errorOutline"}`} style={{ display: 'inline-block', marginBottom: '10px' }} >
            +88{" "}
            <input
              type="tel"
              maxLength={11}
              onKeyUp={(e) => {
                setMobileNumber(e.target.value);
                setShowErrorOutline(false);
              }}
              onKeyDown={(e) => e.key === 'Enter' && loginOperation()}
              id="mobileNumber"
              name="mobileNumber"
              placeholder="আপনার মোবাইল নম্বর"
              className="inputFieldMobile"
            />{" "}
          </span>
            <button
              type="button"
              className="btn numberSubmit"
              onClick={loginOperation}
              style={{ marginBottom: '10px',backgroundColor:'#FB005D' }}
            >
              {isLoading ? (
                <Spinner animation="border" variant="light" size="sm" />
              ) : (
                "GET CODE"
              )}
            </button>
          </>}
          {(playerData?.id) && <>
          <div className={styles['referral-code-desc']}><div>আপনার কোড</div><div className='copyCodeSpan' style={{ fontWeight: '600', position: 'relative', display: 'inline-block' }} onClick={() => { navigator.clipboard.writeText(playerData?.code); handleClick(); }}>{playerData?.code} <FaCopy />
              {showTooltip && (
                <div style={tooltipStyle}>Copied</div>
              )}
            </div>
          </div></>}
          </div>
        </div>
        <div className={`${styles['referral-bottom']} ${playerData?.id ? '' : 'linkDisabled'}`}>
          <div className={styles['referral-bottom-content']}>
            <div className={styles['referral-bottom-content-item']}>
              <div className={styles['referral-bottom-content-title']}>Send the link to your friends</div>
              <div className={styles['referral-bottom-content-icon-container']}>
                <img src={whatsappIcon} onClick={() => { shareSocial('wp') }} alt="whatsapp" className={styles['referral-bottom-content-icon']} />
                <img src={messengerIcon} onClick={() => { shareSocial('msngr') }} alt="messenger" className={styles['referral-bottom-content-icon']} />
                <img src={messageIcon} onClick={() => { shareSocial('sms') }} alt="message" className={styles['referral-bottom-content-icon']} />
              </div>
            </div>
            <div className={styles['referral-bottom-content-item']}>
              <div className={styles['referral-bottom-content-title']}>Share on Social</div>
              <div className={styles['referral-bottom-content-icon-container']}>
                <img src={telegramIcon} onClick={() => { shareSocial('tlg') }} alt="telegram" className={styles['referral-bottom-content-icon']} />
                <img src={twitterIcon} onClick={() => { shareSocial('twt') }} alt="twitter" className={styles['referral-bottom-content-icon']} />
                <img src={facebookIcon} onClick={() => { shareSocial('fb') }} alt="facebook" className={styles['referral-bottom-content-icon']} />
              </div>
            </div>
          </div>
        </div>
        {playerData?.id &&<ReferralHistory />}
      </div>
    </React.Fragment>
  )
}

export default Referral