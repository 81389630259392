import React, { useContext, useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import styles from './OtpModal.module.css'
import '../../../assets/css/modal.css'
import { baseUrl, dataContext, portal } from '../../../App';
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { useSearchParams } from 'react-router-dom';
import xImg from './assets/x.png'
import otpIcon from './assets/otp_icon.png'
const OtpModal = ({ showOtpModal, setShowOtpModal, activeBottom }) => {
  const { token, setToken, playerData, setPlayerData } = useContext(dataContext);
  const [otpCode, setOtpCode] = useState("");
  const [showErrorOutline, setShowErrorOutline] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [isOtpOkay, setIsOtpOkay] = useState(false);
  const [errorMessage, setErrorMessage] = useState(``);
  const [searchParams, setSearchParams] = useSearchParams();

  const config = {
    headers: { Authorization: `Token ${localStorage.getItem("OtpAuthToken")}` },
  };
  function hideModalOperation() {
    setShowOtpModal(false);
  }
  function checkOtp() {
    var otpPattern = /^\d{6}$/;
    if (otpCode.match(otpPattern)) {
      console.log(otpCode,'true')
      return true;
    } else {
      console.log(otpCode,'false')
      return false;
    }
  }
  function submitOperation() {
    console.log(otpCode)
    axios
      .post(
        `${baseUrl}/api/otpverify_api/`,
        { otp: otpCode, company_id: portal, msisdn: localStorage.getItem("user_msisdn") },
        config
      )
      .then((response) => {
        let data = response.data;
        let newToken = response.data.token;
        localStorage.setItem("AuthToken", newToken);
        setToken(newToken);
        console.log(data);
        console.log(activeBottom);
        if (activeBottom === "campaign") {
          const paramsObject = {};

          for (const [key, value] of searchParams) {
            paramsObject[key] = value;
          }

          console.log(paramsObject);
          const config = {
            headers: { Authorization: `Token ${newToken}` },
          };
          let serviceType = 'Daily';
          if (localStorage.getItem("redirectCampaign") === "tournament") { serviceType = 'Tournament' }
          axios
            .post(
              `${baseUrl}/subscription/create/`,
              { portal: portal, qcid: sessionStorage.getItem("cid"), "service_type": serviceType, ...paramsObject },
              config
            )
            .then((response) => {
              let data = response.data;
              setShowOtpModal(false);
              setIsLoading(false);
              console.log(data);
              if (data?.redirectURL) {
                console.log(`${data?.redirectURL}`);
                window.location.replace(`${data?.redirectURL}`);
              }
            });
        } else {
          hideModalOperation()
        }
      }).catch(error => {
        console.log(error);
        if (error?.response?.data?.error) {
          setShowError(true);
          setErrorMessage(error?.response?.data?.error)
        }
      })
  }




  return (
    <>
      <Modal show={showOtpModal} centered dialogClassName={styles.otpModalDialog} contentClassName={styles.otpModalContent} onHide={hideModalOperation} backdrop="static">
        <img src={xImg} alt="logo" className={styles.xImg} onClick={hideModalOperation} />
        <Modal.Body className={styles.otpModalBody}>
          <img src={otpIcon} alt="logo" className={styles.otpIcon} />
          <div className={styles.infoContainer}>
            <span className={styles.infoText}>OTP দিন</span>
            <div className={styles.inputContainer}>
                <span>OTP নম্বর</span>
              <span className={`${styles.inputSpan}`}>
                <input
                  type="number"
                  maxLength={6}
                  minLength={6}
                  onKeyUp={(e) => {
                    setOtpCode(e.target.value);
                    setIsOtpOkay(checkOtp());
                  }}
                  onChange={(e) => {
                    setOtpCode(e.target.value);
                    setIsOtpOkay(checkOtp());
                  }}
                  onKeyDown={(e) => e.key === 'Enter' && submitOperation()}
                  id="otpCode"
                  name="otpCode"
                  placeholder="OTP নম্বর দিন"
                  className={styles.inputFieldOtp}
                />{" "}
              </span>
              {showError && <div style={{ color: "#ff0000", textAlign: 'center', fontWeight: 600 }}>* {errorMessage}</div>}
              <button
                type="button"
                className={`btn ${styles.numberSubmit}`}
                onClick={submitOperation}
                disabled={!isOtpOkay}
                style={{ backgroundColor: !isOtpOkay ? "#757575" : "#FB005D" }}
              >
                {isLoading ? (
                  <Spinner animation="border" variant="light" size="sm" />
                ) : (
                  "সাবমিট করুন"
                )}
              </button>
            </div>
          </div>

          {/* End */}
        </Modal.Body>
      </Modal>
    </>
  )
}

export default OtpModal