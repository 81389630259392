import React from 'react';
import styles from './Leaderboard.module.css';
import handleImageError from '../../helper/defaultImage';

const CurrentUserCard = ({ currentUserData, playerData, baseUrl }) => {
  if (!currentUserData?.User_Rank || currentUserData?.User_Rank === "0") {
    return null;
  }

  return (
    <div className={styles.currentUserSection}>
      <div className={styles.userRow} style={{ boxShadow: 'none' }}>
        <div className={styles.rank}>{currentUserData?.User_Rank ?? '-'}</div>
        <img
          src={playerData?.avatar_img 
            ? `${baseUrl}${playerData?.avatar_img}` 
            : require(`../../assets/avatar/avatar${currentUserData?.avatar_id || 1}.png`)}
          alt="User"
          className={styles.userAvatar}
          onError={handleImageError}
        />
        <div className={styles.userInfo}>
          <div className={styles.userMsisdn}>{`আপনার স্কোর`}</div>
          <div className={styles.timeAndScore}>
            <span>{currentUserData?.time_taken ?? '-'}s</span>
            <span>{currentUserData?.score ?? '-'}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CurrentUserCard; 