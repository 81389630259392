import React, { useContext, useEffect } from 'react'
import './ContactModal.css'
import '../../../assets/css/modal.css'
import { dataContext } from '../../../App';
import activityLogSubmission from '../../../helper/activitylog';
import analyticEvent from '../../../helper/gaEvent';
import styles from './ContactModal.module.css';
import { pushCleverTapEvent } from '../../../helper/clevertapFunction';
import { FaFacebook,FaWhatsapp,FaFacebookMessenger,FaInstagram } from "react-icons/fa";
const ContactModal = ({ showContactModal, setShowContactModal, setShowRefundModal }) => {
  const { token, setToken, playerData, setPlayerData } = useContext(dataContext);

  function hideModalOperation() {
    setShowContactModal(false)
  }

  useEffect(() => {
    let msisdn = null;

    if (playerData?.id) {
      msisdn = playerData?.msisdn;
    }
    activityLogSubmission("contacts", msisdn);
    analyticEvent('contact', 'contact-view')
    pushCleverTapEvent('CheckIn-Help');
  }, [])


  return (
    <div className={styles.contactContainer}>
      <div className={styles.contactHeader}>
        <img src={require('./help.png')} className={styles.contactModalIcon} alt="help" />
        <span className={styles.contactModalTitle}>কুইজার্ড হেল্প</span>
      </div>
      
      <div className={styles.faqList}>
        <div className={styles.faqItem}>
          <div className={styles.question}>হেল্পলাইন নাম্বারটা কি?</div>
          <div className={styles.answer}>
            <a href="tel:+8801988117755">৮৮০১৯৮৮১১৭৭৫৫</a>
          </div>
        </div>

        <div className={styles.faqItem}>
          <div className={styles.question}>হেল্পলাইন ফোন করার সময়সূচী?</div>
          <div className={styles.answer}>
            রবিবার থেকে বৃহস্পতিবার সকাল ১০টা থেকে বিকাল ৬ টা পর্যন্ত।
          </div>
        </div>

        <div className={styles.faqItem}>
          <div className={styles.question}>সাপোর্ট ইমেইল আইডি কি?</div>
          <div className={styles.answer}>
            <a href="mailto:quizard.live@gmail.com">quizard.live@gmail.com</a>
          </div>
        </div>

        <div className={styles.faqItem}>
          <div className={styles.question}>Quizard লিংকটি কি?</div>
          <div className={styles.answer}>
            <a href="https://www.quizard.live/" target="_blank" rel="noopener noreferrer">https://www.quizard.live/</a>
          </div>
        </div>

        <div className={styles.faqItem}>
          <div className={styles.question}>Quizard এর ফেসবুক লিংকটি কি?</div>
          <div className={styles.answer}>
            <a href="https://www.facebook.com/quizard2024" target="_blank" rel="noopener noreferrer">www.fb.com/quizard2024</a>
          </div>
        </div>

        <div className={styles.faqItem}>
          <div className={styles.question}>আমাদের সাথে সরাসরি যোগাযোগ করতে পারেন এভাবেও -</div>
          <div className={`${styles.answer} ${styles.socialIcons}`}>
            <a href="https://www.facebook.com/quizard2024/" target="_blank">
              <FaFacebook />
            </a>
            <a href="https://www.messenger.com/t/quizard2024" target="_blank">
              <FaFacebookMessenger />
            </a>
            <a href="whatsapp://send?phone=+8801988117755" target="_blank">
              <FaWhatsapp />
            </a>
            <a href="https://www.instagram.com/quizard.live" target="_blank">
              <FaInstagram />
            </a>
          </div>
        </div>

        {/* <div className={styles.faqItem}>
          <div className={styles.question}>Quizard থেকে আন্সাবস্ক্রাইব করতে নিচের বাটন ক্লিক করতে হবে-</div>
          <div className={styles.answer}>
            {((playerData?.isSubscribe) || (playerData?.isSubscribeTournament)) && (
              <div className={styles['unsubscribe-button-container']}>
                <button 
                  className={styles['unsubscribe-btn']} 
                  onClick={() => { 
                    setShowRefundModal(true); 
                    setShowContactModal(false) 
                  }}
                >
                  <span>Unsubscribe</span>
                </button>
              </div>
            )}
          </div>
        </div> */}
      </div>
    </div>
  )
}

export default ContactModal