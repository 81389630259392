import React from 'react';
import ReactDOM from 'react-dom/client';
import clevertap from 'clevertap-web-sdk';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// clevertap.privacy.push({optOut: false}) // Set the flag to true, if the user of the device opts out of sharing their data
// clevertap.privacy.push({useIP: false}) 
// clevertap.init('886-RRK-RR7Z') 
// clevertap.spa = true
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <App />
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
