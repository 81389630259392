import React, { useContext, useEffect, useState } from 'react'
import './QuizPage.css'
import backBtn from '../../assets/img/v2/back.png'
import { baseUrl, categoryEventId, dataContext, portal } from '../../App';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import QuestionBody from './QuestionBody/QuestionBody';
import BlockRound from './BlockRound/BlockRound';
import ResultModal from './ResultModal/ResultModal';
import { pushCleverTapEvent } from '../../helper/clevertapFunction';
const QuizPage = () => {
  const navigate = useNavigate()
  const {token, setToken, playerData, setPlayerData} = useContext(dataContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const eventId = parseInt(searchParams.get("eventId"))
  const [eventDetails, setEventDetails] = useState({})
  const [showQuestions, setShowQuestions] = useState(false)
  const [showBlockModal, setShowBlockModal] = useState(false)
  const [showResultModal, setShowResultModal] = useState(false)
  const [finalScore, setFinalScore] = useState(0)
  const [playerRound, setPlayerRound] = useState(null)
  const [isLoadingEnd, setIsLoadingEnd] = useState(false)
  const [roundChecked, setRoundChecked] = useState(false)
  const [fetchRequest, setFetchRequest] = useState(false)

  function insertData(isRight, mobile, round, questionId, selectedAns, category, eventId) {
    // insert Data Function
    // console.log(isRight, mobile, round, questionId, selectedAns, category, eventId)
    axios.post(`${baseUrl}/api/participant/`, {
      is_right: isRight,
      msisdn: mobile,
      round_number: round,
      question: questionId,
      selected_answer: selectedAns,
      category: category,
      event: eventId,
      portal: portal
    }, {
      headers: { Authorization: `Token ${token}` }
    });
  }

  useEffect(() => {
    if (token && eventId) {
      axios.get(`${baseUrl}/api/clients/participant_profiles/?portal=${portal}`, {
        headers: { Authorization: `Token ${token}` }
      })
        .then(response => {
          setPlayerData(response.data[0])
          let data = response.data[0];
          // console.log(data);
          const config = {
            headers: { Authorization: `Token ${token}` },
          };
          if(categoryEventId.includes(eventId)){
            axios.get(`${baseUrl}/api/rules/?portal=${portal}&is_event=false`,config)
            .then(response => {
              const allEvent = response.data || [];
              let eventDe = allEvent.filter(event => {
                return (event?.id === eventId)
              })
              if(!eventDe.length){
                navigate('../')
              }else{
                setEventDetails(eventDe[0])
                setIsLoadingEnd(true)
              }
            })
          }
          else if (data?.isSubscribe && eventId===34) {
            axios.get(`${baseUrl}/api/rules/?portal=${portal}`,config)
            .then(response => {
              const allEvent = response.data || [];
              let eventDe = allEvent.filter(event => {
                return (event?.id === eventId)
              })
              if(!eventDe.length){
                navigate('../')
              }else{
                setEventDetails(eventDe[0])
                setIsLoadingEnd(true)
              }
          })
          }else if (data?.isSubscribeTournament && eventId !==34) {
            axios.get(`${baseUrl}/api/rules/?portal=${portal}`,config)
            .then(response => {
              const allEvent = response.data || [];
              let eventDe = allEvent.filter(event => {
                return (event?.id === eventId)
              })
              if(!eventDe.length){
                navigate('../')
              }else{
                setEventDetails(eventDe[0])
                setIsLoadingEnd(true)
              }
          })
          }else{navigate('../')          }
        }).catch(res => {
          // console.log(res.response.status === 401)
          localStorage.setItem("AuthToken", "");
          localStorage.setItem("user_msisdn", "");
          setPlayerData({});
          // setShowLoginModal(true);
          setToken(null);
          navigate('../')
        });
    }else{
      navigate('../')
    }

  }, [])
  useEffect(() => {
    if (isLoadingEnd) {
      let playerLeaderboardArr = playerData?.event_history;
      let playerRoundNum = 0;
      let playerLeaderboard = []
      if(playerLeaderboardArr){playerLeaderboard = playerLeaderboardArr.filter((a)=>{if(a.event_id==eventDetails.id){return a}});}
      playerRoundNum = (playerLeaderboard.length)?playerLeaderboard[0]?.round_number__max : 0;
      let eventRound = eventDetails?.round
      setPlayerRound(playerRoundNum)
      // console.log(playerRoundNum,eventRound,eventDetails)
      if (playerRoundNum < eventRound) {
        setShowQuestions(true)
        if(eventId == 34){pushCleverTapEvent('StartPlay-QuizPratidin')}
        else if(eventId == 75){pushCleverTapEvent('StartPlay-SportsQuiz')}
        else if(eventId == 77){pushCleverTapEvent('StartPlay-WinterQuiz')}
        sessionStorage.setItem('played_eventId', eventId);
      } else {
        (playerData.msisdn === '1867125111'
          || playerData.msisdn === '1738246580'
          || playerData.msisdn === '1711082515'
        )?setShowQuestions(true):setShowBlockModal(true);
      }
    }
  }, [isLoadingEnd])
  // CustomEvents Background and Element

  return (
    <div className='quizPageBody' style={{background:'#f5f5f5'}}>

      {(showQuestions) && (<QuestionBody insertData={insertData} eventDetails={eventDetails} finalScore={finalScore} setFinalScore={setFinalScore} setShowResultModal={setShowResultModal} playerRound={playerRound}></QuestionBody>)}
      {showBlockModal && (<BlockRound eventDetails={eventDetails} showBlockModal={showBlockModal} setShowBlockModal={setShowBlockModal}></BlockRound>)}
      {showResultModal && (<ResultModal eventDetails={eventDetails} showResultModal={showResultModal} setShowResultModal={setShowResultModal} finalScore={finalScore}></ResultModal>)}

    </div>
  )
}

export default QuizPage