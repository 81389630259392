import React, { useContext, useEffect, useRef, useState } from "react";
import styles from './BannerPage.module.css'

import "./BannerPart3.css";
import axios from "axios";
import { baseUrl, categoryEventId, dataContext, portal } from "../../App";
import { useNavigate } from "react-router-dom";
import activityLogSubmission from "../../helper/activitylog";
import RotatingBox from "./RotatingBox";
import analyticEvent from "../../helper/gaEvent";
import clevertap from "clevertap-web-sdk";
import BannerCategory from "./BannerCategory";
import CategoryBox from "./categoryBox/categoryBox";
import crawlText from './referAssets/text.png'
import referImage from './referAssets/referral.png'
import gsap from 'gsap';
import { useGSAP } from '@gsap/react';
import { Draggable } from 'gsap/Draggable';
import QuizChallenges from "./QuizChallenges/QuizChallenges";
import PromotionalBanner from "./PromotionalBanner/PromotionalBanner";
import promotionalBanner1 from './PromotionalBanner/assets/promotional_banner1.png'
import promotionalBanner2 from './PromotionalBanner/assets/promotional_banner2.png'
import PlayerStatusCard from "./PlayerStatusCard/PlayerStatusCard";
import PlayingHistory from "./PlayingHistory/PlayingHistory";
gsap.registerPlugin(Draggable);
const BannerPart3 = ({ props }) => {
  const referImageRef = useRef(null);
  const navigate = useNavigate();
  const { token, setToken, playerData, setPlayerData, events, setEvents } =
    useContext(dataContext);
  const {quizClickHandler, showLoginModal, setShowShobDekho, setShowLoginModal, showLoadingModal, setShowLoadingModal, showRedeemModal, setShowRedeemModal, setShowReferModal, setActiveBottom, homePageBodyRef } = props;

  useEffect(() => {
    let msisdn = null;

    if (playerData?.id) {
      msisdn = playerData?.msisdn;
    }
    activityLogSubmission("home", msisdn);
    analyticEvent('home', 'home-view')
    // clevertap.event.push("Product viewed");
    gsap.to(referImageRef.current, { display: 'block', opacity: 1, delay: 3, x: 0, zIndex: 78, duration: 1 }); // <-- automatically reverted
    Draggable.create(referImageRef.current, {
      type: 'x,y', // or you can use 'rotation' for rotating elements
      edgeResistance: 0.65,
      bounds: homePageBodyRef.current, // or a specific container
      allowEventDefault: true,
      inertia: true,
    });
  }, []);
  let userScore = [];
  userScore = events.map((event) => {
    let userLeaderboard = [];
    if (playerData?.id) {
      userLeaderboard = playerData?.leaderboard?.filter((singleEvent) => {
        if (singleEvent.event_id == event.id) {
          return singleEvent;
        }
      });
      return {
        eventTitle: event.events,
        score: userLeaderboard[0]?.score,
      };
    } else {
      return {
        eventTitle: event.events,
        score: "-",
      };
    }
  });
  // console.log(userScore);
  let userJsx = ``;
  for (let i = 0; i < userScore.length; i++) {
    if (i == 0) {
      userJsx = `<div className="singleEventScore">
      <div>${userScore.eventTitle}</div>
      <div>${userScore.score}</div>
    </div>`;
    } else {
      userJsx += `
    <div className="horizontalBar"></div>
    <div className="singleEventScore">
      <div>${userScore.eventTitle}</div>
      <div>${userScore.score}</div>
    </div>`;
    }
  }
  return (
    <>
      <div className={styles.bannerPageBody}>
        <PlayerStatusCard data={playerData} />
        <QuizChallenges quizClickHandler={quizClickHandler} setShowShobDekho={setShowShobDekho} />
        
        <div className={styles.newYearPromoDiv}>
          <img src={`https://ms.purplepatch.online/bkash-quiz-banner/homepage-promotional/quizard/promotionalBanner2.jpg`} className={styles.newYearPromo} alt="" onClick={() => {
            activityLogSubmission('promotional-banner-clicked2', playerData?.msisdn);
            quizClickHandler(80,playerData);
          }} />
        </div>

        <div className={styles.wordlyPromoDiv}>
          <img src={`https://ms.purplepatch.online/bkash-quiz-banner/homepage-promotional/quizard/promotionalBanner.jpg`} className={styles.wordlyPromo} alt="" onClick={() => {
            activityLogSubmission('promotional-banner-clicked', playerData?.msisdn);
            window.open('https://wordly.quizard.live/?qcid=72');
          }} />
        </div>
        <CategoryBox props={{ events, quizClickHandler }} />
        <PromotionalBanner
          bannerSrc={`https://ms.purplepatch.online/bkash-quiz-banner/promotional-banner/promotional_banner1.png`}
          handleClick={() => { setActiveBottom('subscription');activityLogSubmission('10tk_cash_back'); }} />
        <PlayingHistory events={events} playerData={playerData} quizClickHandler={quizClickHandler} />
        <PromotionalBanner bannerSrc={`https://ms.purplepatch.online/bkash-quiz-banner/promotional-banner/promotional_banner2.png`} handleClick={() => { setActiveBottom('referral');activityLogSubmission('10tk_cash_in'); }} />
      </div>
    </>
  );
};

export default BannerPart3;
