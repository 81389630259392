import React, { useContext } from 'react'
import styles from './QuizChallenges.module.css'
import { LuClock3 } from "react-icons/lu";
import { GrCircleQuestion } from "react-icons/gr";
import { dataContext } from '../../../App'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import { convertToBengaliNumber } from '../helper';

const QuizChallenges = ({ quizClickHandler, setShowShobDekho, donotShowSwiper }) => {
  const { events,playerData } = useContext(dataContext);
  return (
    <div className={donotShowSwiper ? styles.quizChallengesCategory : styles.quizChallenges}>
      <div className={styles.header} style={{padding: donotShowSwiper ? '0px 0px' : '0px 16px',marginBottom: donotShowSwiper ? '0px' : '16px'}}>
        <h2 className={styles.title}>কুইজ চ্যালেঞ্জেস</h2>
        <a href="#" className={styles.viewAll} onClick={() => setShowShobDekho(true)}>সবগুলো দেখো</a>
      </div>
      {!donotShowSwiper && events?.length > 0 && (<>
      <Swiper
        modules={[Pagination, Autoplay]}
        spaceBetween={20}
        slidesPerView={1.2}
        speed={1000}
        loop={events?.length > 1}
        autoplay={events?.length > 1 ? {
          delay: 2000,
          disableOnInteraction: false,
        } : false}
        pagination={{
          clickable: true,
          el: `.${styles.pagination}`,
        }}
        breakpoints={{
          320: {
            slidesPerView: 1.2,
            spaceBetween: 16,
          }
        }}
      >
        {events?.map((event) => (
          <SwiperSlide key={event.id}>
            <div className={styles.quizCard} onClick={() => quizClickHandler(event.id,playerData)}>
              <img
                src={event.banner_url}
                alt={event.events}
                className={styles.bannerImage}
              />
              <div className={styles.quizInfo}>
                <h3 className={styles.quizTitle}>{event.events}</h3>
                <div className={styles.quizMeta}>
                  <div className={styles.metaItem}>
                    <LuClock3 className={styles.icon} />
                    <span>{convertToBengaliNumber(event.allocated_time)} সে.</span>
                  </div>
                  <div className={styles.metaItem}>
                    <GrCircleQuestion className={styles.icon} />
                    <span>{convertToBengaliNumber(event.question_set)}টি প্রশ্ন</span>
                  </div>
                  <button
                    className={styles.playButton}
                  >
                    খেলুন →
                  </button>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>

        <div className={styles.pagination} />
      </>)}
      {(donotShowSwiper === true) &&<>
        {events?.map((event) => (
            <div className={styles.quizCard}
              onClick={() => quizClickHandler(event.id,playerData)}
            >
              <img
                src={event.banner_url}
                alt={event.events}
                className={styles.bannerImage}
              />
              <div className={styles.quizInfo}>
                <h3 className={styles.quizTitle}>{event.events}</h3>
                <div className={styles.quizMeta}>
                  <div className={styles.metaItem}>
                    <LuClock3 className={styles.icon} />
                    <span>{convertToBengaliNumber(event.allocated_time)} সে.</span>
                  </div>
                  <div className={styles.metaItem}>
                    <GrCircleQuestion className={styles.icon} />
                    <span>{convertToBengaliNumber(event.question_set)}টি প্রশ্ন</span>
                  </div>
                  <button
                    className={styles.playButton}
                  >
                    খেলুন →
                  </button>
                </div>
              </div>
            </div>
          ))}
      
      </>}
    </div>
  )
}

export default QuizChallenges