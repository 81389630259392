import React, { useContext } from 'react';
import styles from './SubscriptionStatus.module.css';
import { fetchSubscriptionUrl } from '../../../services';
import { dataContext } from '../../../App';

const SubscriptionStatus = ({ isSubscribe, isSubscribeTournament,setShowLoginModal }) => {
  const { token, playerData } = useContext(dataContext);
  function handleSubscriptionClick(service_type){
    if(!playerData?.id){
      setShowLoginModal(true);
      return;
    }
    fetchSubscriptionUrl(token,service_type)
    .then((res)=>{
      if(res?.redirectURL){
        window.location.replace(`${res?.redirectURL}`);
      }else{
        console.error('Something went wrong. Redirect URL not found',res);
      }
    })
  }
  return (
    <div className={styles.subscriptionContainer}>
      <h2 className={styles.title}>সাবস্ক্রিপশন</h2>
      
      {/* Weekly Subscription Box */}
      <div className={styles.subscriptionBox}>
        <div className={styles.header}>
          <span className={styles.type} style={{background:'#12793C'}}>কুইজ প্রতিদিন সাবস্ক্রিপশন</span>
          {isSubscribe && <span className={styles.badge}>সাবস্ক্রাইবড</span>}
        </div>
        
        <div className={styles.price}>
          <span className={styles.amount}>৳৩০</span>
          <span className={styles.duration}>৭ দিনের জন্য</span>
        </div>

        <div className={styles.features}>
          <div className={styles.feature}>প্রতিদিন ২ রাউন্ড কুইজ প্রতিদিন</div>
        </div>
        {!isSubscribe && <button className={styles.subscribeButton} onClick={()=>{handleSubscriptionClick('Daily')}}>সাবস্ক্রাইব করুন</button>}
      </div>

      {/* Tournament Subscription Box */}
      <div className={styles.subscriptionBox} style={{marginBottom: '0px'}}>
        <div className={styles.header}>
          <span className={styles.type} style={{background:'#0081DC'}}>টুর্নামেন্ট সাবস্ক্রিপশন</span>
          {isSubscribeTournament && <span className={styles.badge}>সাবস্ক্রাইবড</span>}
        </div>
        
        <div className={styles.price}>
          <span className={styles.amount}>৳৩০</span>
          <span className={styles.duration}>৭ দিনের জন্য</span>
        </div>

        <div className={styles.features}>
          <div className={styles.feature}>সকল টুর্নামেন্টে অংশগ্রহণ</div>
        </div>
        {!isSubscribeTournament && <button className={styles.subscribeButton} onClick={()=>{handleSubscriptionClick('Tournament')}}>সাবস্ক্রাইব করুন</button>}
      </div>
    </div>
  );
};

export default SubscriptionStatus;
