import React, { useContext, useEffect, useState } from 'react'
import styles from './PlayerStatusCard.module.css'
import { baseUrl, dataContext } from '../../../App';
import { convertToBengaliNumber } from '../helper';
import axios from 'axios';
import { fetchProfileExtraData } from '../../../services';
import handleImageError from '../../../helper/defaultImage';

const PlayerStatusCard = ({data}) => {
  const {token,extraData, setExtraData} = useContext(dataContext);
  useEffect(() => {
    if(!token) return;
    fetchProfileExtraData(token).then((res) => {
      console.log(res);
      setExtraData(res);
    });
  }, [token,data]);
  if(!data?.id) return <></>;
  return (<div className={styles.playerStatusCardContainer}>
    <div className={styles.playerStatusCard}>
      <div className={styles.header}>
        <div className={styles.userInfo}>
          <img src={data?.avatar_img
                    ? `${baseUrl}${data?.avatar_img}`
                    : require(`../../../assets/avatar/avatar${data?.avatar_id || 1}.png`)} alt="avatar"
                     className={styles.avatar} onError={handleImageError} />
          <div className={styles.nameContainer}>
            <h3>{data?.name || `0${data?.msisdn}`} 🔥</h3>
            <p>চলো কুইজ খেলি কুইজার্ডের সাথে </p>
          </div>
        </div>
        <div 
          className={`${styles.subscriptionBadge} ${(!data.isSubscribe && !data.isSubscribeTournament) ? styles.unsubscribed : ''}`}
        >
          {data.isSubscribe || data.isSubscribeTournament ? 'সাবস্ক্রাইবড' : 'আনসাবস্ক্রাইবড'}
        </div>
      </div>
      
      <div className={styles.statsContainer}>
        <div className={styles.statItem}>
          <h4>কমপ্লিট কুইজ</h4>
          <p className={styles.statValue}>{extraData?.CompletedQuiz ? convertToBengaliNumber(extraData.CompletedQuiz) : '০'}</p>
        </div>
        <div className={styles.divider}></div>
        <div className={styles.statItem}>
          <h4>উইনিং রেট</h4>
          <p className={styles.statValue}>{extraData?.DailyWinningRate ? convertToBengaliNumber(Math.round(extraData.DailyWinningRate)) : '০'}%</p>
        </div>
        <div className={styles.divider}></div>
        <div className={styles.statItem}>
          <h4>টোটাল আয়</h4>
          <p className={styles.statValue}>৳{extraData?.TotalWinAmount ? convertToBengaliNumber(extraData.TotalWinAmount) : '০'}</p>
        </div>
      </div>
    </div></div>
  )
}

export default PlayerStatusCard