import React, { useContext, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal';
import './ShobDekho.css'
import '../../../assets/css/modal.css'
import { dataContext } from '../../../App';
import activityLogSubmission from '../../../helper/activitylog';
import analyticEvent from '../../../helper/gaEvent';
import { LuClock3 } from "react-icons/lu";
import { GrCircleQuestion } from "react-icons/gr";
import styles from './ShobDekho.module.css';
import { convertToBengaliNumber } from './helper';
const ShobDekho = ({ showShobDekho, setShowShobDekho, setShowRefundModal,quizClickHandler }) => {
  const { events,playerData } = useContext(dataContext);

  function hideModalOperation() {
    setShowShobDekho(false)
  }

  useEffect(() => {
    activityLogSubmission("shobdekho");
    analyticEvent('shobdekho', 'shobdekho-view')
  }, [])


  return (
    <>
      <Modal show={showShobDekho} centered dialogClassName="modal-dialog" contentClassName="modalContentShobDekho" onHide={hideModalOperation}>
        <Modal.Header closeButton >
        </Modal.Header>
        <Modal.Body style={{ maxHeight: "65vh", overflow: "scroll", display: 'block', overflowX: 'hidden', padding: '6px 20px 24px 20px' }}>
          {events?.map((event) => (
            <div className={styles.quizCard} onClick={() => {quizClickHandler(event.id,playerData); setShowShobDekho(false); }}>
              <img
                src={event.banner_url}
                alt={event.events}
                className={styles.bannerImage}
              />
              <div className={styles.quizInfo}>
                <h3 className={styles.quizTitle}>{event.events}</h3>
                <div className={styles.quizMeta}>
                  <div className={styles.metaItem}>
                    <LuClock3 className={styles.icon} />
                    <span>{convertToBengaliNumber(event.allocated_time)} সে.</span>
                  </div>
                  <div className={styles.metaItem}>
                    <GrCircleQuestion className={styles.icon} />
                    <span>{convertToBengaliNumber(event.question_set)}টি প্রশ্ন</span>
                  </div>
                  <button
                    className={styles.playButton}
                  >
                    খেলুন →
                  </button>
                </div>
              </div>
            </div>
          ))}
        </Modal.Body>
      </Modal>
    </>
  )
}

export default ShobDekho