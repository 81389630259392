export const formatDate = (dateString) => {
  const date = new Date(dateString);
  const bengaliNumerals = ['০', '১', '২', '৩', '৪', '৫', '৬', '৭', '৮', '৯'];
  
  const convertToBengali = (number) => {
    return String(number).split('').map(digit => bengaliNumerals[digit]).join('');
  };

  const bengaliMonths = [
    'জানুয়ারি', 'ফেব্রুয়ারি', 'মার্চ', 'এপ্রিল', 'মে', 'জুন',
    'জুলাই', 'আগস্ট', 'সেপ্টেম্বর', 'অক্টোবর', 'নভেম্বর', 'ডিসেম্বর'
  ];

  return `${convertToBengali(date.getDate())} ${bengaliMonths[date.getMonth()]}, ${convertToBengali(String(date.getFullYear()).slice(2))}`;
}; 