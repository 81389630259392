import React from 'react'
import styles from './Category.module.css'
import CategoryBox from '../BannerPart3/categoryBox/categoryBox'
import QuizChallenges from '../BannerPart3/QuizChallenges/QuizChallenges'
const Category = ({events, quizClickHandler,setShowShobDekho}) => {
  return (
    <div className={styles.category}>
      <CategoryBox props={{ events, quizClickHandler }} />
      <div className={styles.quizChallenges}>
        <QuizChallenges quizClickHandler={quizClickHandler} setShowShobDekho={setShowShobDekho} donotShowSwiper={true}/>
      </div>
    </div>
  )
}

export default Category