import React, { useContext, useEffect, useRef, useState } from 'react'
import './QuestionBody.css'
import rightImg from '../../../assets/img/v2/right.png'
import wrongImg from '../../../assets/img/v2/wrong.png'
import rightAudio from '../../../assets/sound/right.wav'
import wrongAudio from '../../../assets/sound/wrong2.mp3'
import timesUpAudio from '../../../assets/sound/timesUp.mp3'
import timerAudio from '../../../assets/sound/timer.mp3'
import axios from 'axios'
import { baseUrl, dataContext, portal } from '../../../App'
import { useElapsedTime } from 'use-elapsed-time'
import { colorCleanUp, secondsToMS, translateToBanglaNum } from './questionHelper'
import TimerBoxLinear from '../TimerBoxLinear/TimerBoxLinear'
import ProgressBar from '../ProgressBar/ProgressBar'
import activityLogSubmission from '../../../helper/activitylog'
import SingleOption from './SingleOption'
import clockImg from './assets/clock.png'
import logoImg from './assets/white_logo.png'
import rightCircle from './assets/right_circle.png'
import wrongCircle from './assets/wrong_circle.png'
import bottomImage from './assets/bottomImage.png'
import bottomSportsImage from './assets/bottomSports.png'
import styles from './QuestionBody.module.css'
// import CircularTimer from '../CircularTimer/CircularTimer'
import CircularTimer from '../../../components/CircularTimer/CircularTimer';
import { useNavigate } from 'react-router-dom'
import { FaHome, FaMedal } from 'react-icons/fa'



const QuestionBody = ({ eventDetails, finalScore, setFinalScore, setShowResultModal, playerRound, insertData }) => {
  const { token, setToken, playerData, setPlayerData, events, setEvents } = useContext(dataContext);
  const isSubmitRef = useRef(false);
  // const [timerTick, setTimerTick] = useState(new Audio(timerAudio)) 
  const [allQuestions, setAllQuestions] = useState([])
  const [currentQues, setCurrentQues] = useState({})
  const [currentQuesIndex, setCurrentQuesIndex] = useState(null)
  const [isLoadingEnd, setIsLoadingEnd] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [totalRightAnswer, setTotalRightAnswer] = useState(0)
  const [totalWrongAnswer, setTotalWrongAnswer] = useState(0)
  const [timerWidth, setTimerWidth] = useState(100)
  const [progressWidth, setProgressWidth] = useState(0)
  const [singleTimerWidth, setsingleTimerWidth] = useState(100)
  const [timerTime, setTimerTime] = useState(0)
  const [singleTime, setSingleTime] = useState(6)
  const [isPlaying, setIsPlaying] = useState(false);
  const { elapsedTime } = useElapsedTime({ isPlaying });
  const navigate = useNavigate();

  const [singleTimerLower, setSingleTimerLower] = useState(0.0)

  const tickingElm = useRef(0);
  useEffect(() => {
    axios.get(`${baseUrl}/api/questions/?rules=${eventDetails.id}&portal=${portal}`, {
      headers: { Authorization: `Token ${token}` }
    })
      .then(response => {
        setAllQuestions(response.data)
        setIsLoadingEnd(true)
        setCurrentQuesIndex(0)
        let totalTime = eventDetails.allocated_time;
        let tempSingleTime = totalTime / response.data.length;
        setSingleTime(tempSingleTime)
        // console.log(totalTime)
        setTimerTime(totalTime)
        // insertData(0, playerData.msisdn, playerRound + 1, 1, 'initial', 'general', eventDetails.id)
        tickingElm.current = new Audio(timerAudio)
        tickingElm.current.play()
        tickingElm.current.loop = true;

        // timerTick.load()
        // timerTick.play()
        // timerTick.loop = true
      })
  }, [])


  useEffect(() => {
    setFinalScore(totalRightAnswer)
  }, [totalRightAnswer])

  // timerCode
  // let timerTime = eventDetails.allocated_time;
  let remainingWidth = (((timerTime - elapsedTime) / timerTime) * 100).toFixed(2);
  let remainingTime = (((timerTime - elapsedTime))).toFixed(2);

  useEffect(() => {
    setTimerWidth(remainingWidth)
  }, [remainingWidth])

  if (elapsedTime > timerTime) {
    if (isPlaying) {
      setIsPlaying(false)
      gameDone()
    }
  }

  // singleTimer with event time / question no
  let tempTime = (eventDetails?.allocated_time) || 6;
  let tempQuesLenght = (allQuestions?.length) || 1;

  // let singleTime = (tempTime / tempQuesLenght);
  let singleTimerUpper = singleTime + singleTimerLower;
  let singleTimerRemaining = (((singleTimerUpper - elapsedTime) / singleTime) * 100).toFixed(2);
  useEffect(() => {
    setsingleTimerWidth(singleTimerRemaining)
  }, [singleTimerRemaining])
  // if (singleTimerUpper <= elapsedTime) {
  //   if (isPlaying && (!isSubmitted)) {
  //     setIsPlaying(false)
  //     new Audio(timesUpAudio).play();
  //     singleTimerSubmit()
  //   }
  // }
  // Timer Done

  useEffect(() => {
    let msisdn = null;

    if (playerData?.id) {
      msisdn = playerData?.msisdn;
    }
    activityLogSubmission(`events-${eventDetails.id}`, msisdn);
  }, [])

  // Loading: [Done]
  useEffect(() => {
    if (isLoadingEnd) {
      setCurrentQues(allQuestions[currentQuesIndex])
      setIsPlaying(true)
      setSingleTimerLower(parseFloat(elapsedTime.toFixed(1)))
      // setSingleTime(allQuestions[currentQuesIndex].time_allocated)
      // setSingleTime((allQuestions[currentQuesIndex]?.time_allocated) ? allQuestions[currentQuesIndex].time_allocated : 6)
    }

    let progress = allQuestions.length ? ((currentQuesIndex) ? ((currentQuesIndex) / allQuestions.length) : 0) : 0;
    setProgressWidth((progress * 100).toFixed(2))
  }, [currentQuesIndex])

  useEffect(() => {
    return () => {
      tickingElm.current.pause()
      console.log("in cleanup")
    }
  }, [])

  // console.log(allQuestions)
  function handleAnswerClick(event, answerId) {
    if (isSubmitRef.current) return;
    isSubmitRef.current = true;
    setIsSubmitted(true)

    let isRight = 0;
    let mobile = playerData.msisdn;
    let round = playerRound + 1;
    let questionId = currentQues.id;
    let selectedAns = `Option${answerId}`;
    let category = currentQues.category;

    if (parseInt((currentQues.ans.trim()).slice(-1)) === answerId) {
      event.currentTarget.classList.add('bg_right')
      new Audio(rightAudio).play();
      let totalRightScore = totalRightAnswer + 1;
      sessionStorage.setItem('quizPlay_totalRightScore', totalRightScore);
      setTotalRightAnswer((totalRightAnswer + 1));


      isRight = 1;
    } else {
      event.currentTarget.classList.add('bg_wrong')
      new Audio(wrongAudio).play()
      let totalWrongScore = totalWrongAnswer + 1;
      sessionStorage.setItem('quizPlay_totalWrongScore', totalWrongScore);
      setTotalWrongAnswer((totalWrongAnswer + 1))
    }
    insertData(isRight, mobile, round, questionId, selectedAns, category, eventDetails.id);
    if (currentQuesIndex < (allQuestions.length - 1)) {
      setTimeout(() => {
        setIsPlaying(false)
        colorCleanUp('singleOptions')
        setCurrentQuesIndex((currentQuesIndex + 1))
        setIsSubmitted(false)
        isSubmitRef.current = false;
      }, 400);
    } else {
      gameDone()
    }
  }

  function gameDone() {
    sessionStorage.setItem('quizPlay_eventId', eventDetails.id);
    sessionStorage.setItem('quizPlay_passingScore', eventDetails.passing_score);
    tickingElm.current.pause()
    setIsPlaying(false)
    setProgressWidth('100')
    navigate(`../?page=result&eventId=${eventDetails.id}`)
  }

  function singleTimerSubmit() {
    // console.log('singleSubmitted')
    insertData(0, playerData.msisdn, playerRound + 1, currentQues.id, 'notSelected', 'general', eventDetails.id)
    setIsSubmitted(false)
    let totalWrongScore = totalWrongAnswer + 1;
    sessionStorage.setItem('quizPlay_totalWrongScore', totalWrongScore);
    setTotalWrongAnswer((totalWrongAnswer + 1))
    if (currentQuesIndex < (allQuestions.length - 1)) {
      setCurrentQuesIndex((currentQuesIndex + 1))
    } else {
      gameDone()
    }
  }

  return (
    <>
      <div className={styles.quizNavbar}>
        <div className={styles.timerSection}>
          <div className={styles.timeRemaining}>
            <img src={clockImg} style={{ height: '18px' }} alt="Clock" className={styles.clock} />
            <span>{translateToBanglaNum(secondsToMS(parseFloat((remainingTime < 0) ? '0' : remainingTime)))}</span>
          </div>
          <img src={logoImg} alt="Logo" className={styles.logo} />
          <div className={styles.questionCounter}><span>প্রশ্ন:</span>
            <span>{translateToBanglaNum((currentQuesIndex + 1).toString())}/{translateToBanglaNum(allQuestions.length.toString())}</span>
          </div>
        </div>
        <div className={styles.progressBarSection}>
          <div className={styles.progressBar}>
            <div className={styles.progressFill} style={{ width: `${progressWidth}%` }}></div>
          </div>
        </div>
      </div>
      <div className={styles.iconContainer} style={{ display: 'flex', justifyContent: 'space-between', padding: '10px 18px 5px 16px' }}>
        <div onClick={() => {
          tickingElm.current.pause();
          navigate('../?page=home');
        }} style={{ cursor: 'pointer' }}>
          <FaHome size={24} color="#270D6F" />
        </div>
        <div onClick={() => {
          sessionStorage.setItem('leaderEventId', eventDetails.id);
          tickingElm.current.pause();
          navigate('../?page=leader')}} style={{ cursor: 'pointer' }}>
          <FaMedal size={24} color="#270D6F" />
        </div>
      </div>
      <div className={styles.scoreContainer}>
        <div className={styles.scoreItem}>
          <img src={rightCircle} alt="" style={{ height: '18px' }} className={styles.scoreRightCircle} />
          <span>{translateToBanglaNum(totalRightAnswer.toString())}</span>
        </div>
        <div className={styles.circularTimeRemaining} style={{opacity: '0'}}>
          <CircularTimer progress={parseFloat(singleTimerWidth)} />
        </div>

        <div className={styles.scoreItem}>
          <img src={wrongCircle} alt="" style={{ height: '18px' }} className={styles.scoreRightCircle} />
          <span>{translateToBanglaNum(totalWrongAnswer.toString())}</span>
        </div>
      </div>

      <div className={styles.questionBoxWrapper}>
        <div className={styles.questionField}>
          {currentQues?.question}
        </div>
        <div className={styles.optionsField}>
          <SingleOption props={{ currentQues, handleAnswerClick, currentQuesIndex }}></SingleOption>
        </div>
      </div>
      <img src={(eventDetails.id === 75) ? bottomSportsImage : bottomImage} alt="" className={styles.bottomImage} />
      {/* <div className="questionBox">

        <div className="questionFieldOuter">
          <div className="circle1"></div>
          <div className="circle2"></div>
          <div className="questionFieldWrapper">
            <div className="questionField">
              {currentQues?.question}
            </div>
          </div>
        </div>
        <div className="optionsWrapper">
          <div className="optionsField">
            <SingleOption props={{ currentQues, handleAnswerClick, currentQuesIndex }}></SingleOption>
          </div>
        </div>
      </div> */}
    </>
  )
}

export default QuestionBody