import React, { useContext, useEffect, useRef, useState } from "react";
import Navbar from "../../components/Navbar/Navbar";
import Bottombar from "../../components/Bottombar/Bottombar";
import Sidebar from "../../components/Sidebar/Sidebar";
import BannerPart3 from "../../components/BannerPart3/BannerPart3";
import LoginModal from "../../components/LoginModal/LoginModal";
import { dataContext, baseUrl, portal, firebaseAnalytics, categoryEventId } from "../../App";
import Profile from "../../components/Profile/Profile";
import Leaderboard from "../../components/Leaderboard/Leaderboard";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import ContactModal from "../../components/Modal/ContactModal/ContactModal";
import TCModal from "../../components/Modal/TCModal/TCModal";
import ReferralPolicyModal from "../../components/Modal/ReferralPolicyModal/ReferralPolicyModal";
import RefundModal from "../../components/Modal/RefundModal/RefundModal";
import RewardsModal from "../../components/Modal/RewardsModal/RewardsModal";
import styles from './HomePageV3.module.css'
import clevertap from "clevertap-web-sdk";
import openingBannerImg from "./opening_banner.jpg";
import homeImg from "../../assets/img/background/bg.png";
import leaderboardImg from "../../assets/img/v4/BG1.png";
import activityLogSubmission from "../../helper/activitylog";
import analyticEvent from "../../helper/gaEvent";
import LoadingModal from "../../components/Modal/LoadingModal/LoadingModal";
import SubscriptionModal from "../../components/Modal/SubscriptionModal/SubscriptonModal";
import UnSubscriptionModal from "../../components/Modal/UnSubscriptionModal/UnSubscriptionModal";
import ErrorSubscriptionModal from "../../components/Modal/ErrorSubscriptionModal/ErrorSubscriptionModal";
import RedeemModal from "../../components/Modal/RedeemModal/RedeemModal";
import LeaderWinnerList from "../../components/LeaderWinnerList/LeaderWinnerList";
import ReferModal from "../../components/Modal/ReferModal/ReferModal";
import Referral from "../../components/Referral/Referral";
import { pushCleverTapEvent } from "../../helper/clevertapFunction";
import OtpModal from "../../components/Modal/OtpModal/OtpModal";
import ShobDekho from "../../components/Modal/ShobDekho/ShobDekho";
import Category from "../../components/Category/Category";
import Subscription from "../../components/Subscription/Subscription";
import ResultPage from "../../components/ResultPage/ResultPage";
import submitRedeemCode from "../../helper/submitRedeemCode";
const HomePageV3 = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const bottomBarPosition = searchParams.get("page") || "home";
  const { token, setToken, playerData, setPlayerData, events, setOpeningLoader } = useContext(dataContext);
  const [activeBottom, setActiveBottom] = useState(bottomBarPosition);
  const [showSidebar, setShowSidebar] = useState(false);
  const [showOpeningBanner, setShowOpeningBanner] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showRedeemModal, setShowRedeemModal] = useState(false);
  const [showOtpModal, setShowOtpModal] = useState(false);
  const [showContactModal, setShowContactModal] = useState(false);
  const [showShobDekho, setShowShobDekho] = useState(false);
  const [showTCModal, setShowTCModal] = useState(false);
  const [showReferralPolicyModal, setShowReferralPolicyModal] = useState(false);
  const [showRewardsModal, setShowRewardsModal] = useState(false);
  const [showRefundModal, setShowRefundModal] = useState(false);
  const [showLoadingModal, setShowLoadingModal] = useState(false);
  const [showReferModal, setShowReferModal] = useState(false);
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
  const [showErrorSubscriptionModal, setShowErrorSubscriptionModal] = useState(false);
  const [showUnSubscriptionModal, setShowUnSubscriptionModal] = useState(false);
  const [toggleToUpdate, setToggleToUpdate] = useState(false);
  const [activeBoard, setActiveBoard] = useState(sessionStorage.getItem('leaderEventId')?parseInt(sessionStorage.getItem('leaderEventId')) : events[0]?.id);
  const [activeLeaderPage, setActiveLeaderPage] = useState('singleLeaderPage');
  let checkFailedSubscription = (searchParams.get("status") === 'CANCELLED' || searchParams.get("status") === 'FAILED')
  let checkSuccessfulSubscription = (searchParams.get("status") === 'SUCCEEDED')
  // console.log(searchParams)
  const homePageBodyRef = useRef(null);
  let cid = sessionStorage.getItem('cid');
  let openingBanner = sessionStorage.getItem('opening_banner');
  let hiddenPopup = false;
  function hideOpeningBanner() {
    if (!hiddenPopup) {
      setShowOpeningBanner(false)
      hiddenPopup = true;
    }
  }
  useEffect(() => {
    if(playerData?.id) {
      let referCode = sessionStorage.getItem('refer_code');
      let hasAttemptedRedeem = sessionStorage.getItem('attempted_redeem');
      
      if(!playerData?.redeemed_code && referCode && !hasAttemptedRedeem) {
        // Set flag before attempting redeem
        sessionStorage.setItem('attempted_redeem', 'true');
        
        submitRedeemCode(referCode)
          .then(response => {
            // Handle successful redemption
            sessionStorage.removeItem('refer_code');
            console.log(response)
          })
          .catch(error => {
            sessionStorage.removeItem('refer_code');
            // Handle error case
            console.error('Failed to redeem code:', error);
          });
      }
    }
  }, [playerData]);
  useEffect(() => {
    sessionStorage.removeItem('leaderEventId')
  }, []) 
  function popUpBannerFunction(playerData = {}) {
    let openingBanner = sessionStorage.getItem('opening_banner');
    if (sessionStorage.getItem("login_event_id")) return;
    if (openingBanner == 1) return;
    if (searchParams.get('qcid') == 46) {
      setShowOpeningBanner(true);
      sessionStorage.setItem('opening_banner', 1);
      setTimeout(() => {
        hideOpeningBanner();
        popUpBannerClicked();
      }, 5000);
    } else if (playerData?.isSubscribeTournament === false) {
      sessionStorage.setItem('opening_banner', 1);
      setShowOpeningBanner(true);
      setTimeout(() => {
        hideOpeningBanner();
      }, 5000);
    }
  }
  function popUpBannerClicked() {
    quizClickHandlerInLogin(80, playerData);
  }
  useEffect(() => {
    searchParams.delete('page')
    setSearchParams(searchParams)
    // console.log(searchParams)
    if (searchParams.get('qcid')) { sessionStorage.setItem('cid', searchParams.get('qcid')); cid = searchParams.get('qcid') }
  }, [bottomBarPosition]);
  function callFloodlightTag() {
    console.log('calling floodlight tag')
    // Floodlight tag URL
    var floodlightTagUrl = "https://ad.doubleclick.net/ddm/activity/src=14037245;type=sales;cat=quiza0;qty=1;cost=[Revenue];dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=[OrderID]?";

    // Make a request to the Floodlight tag URL using an XMLHttpRequest
    var xhr = new XMLHttpRequest();
    xhr.open("GET", floodlightTagUrl, true);
    xhr.send();
  }
  useEffect(() => {
    if (searchParams.get("status") === 'SUCCEEDED') {
      setShowSubscriptionModal(true)
      analyticEvent('bkash-subscription', 'bkash-subscription-success')
      activityLogSubmission("subscription_success", localStorage.getItem('user_msisdn'));
      pushCleverTapEvent('New-Subscription');
    }
    if (searchParams.get("status") === 'CANCELLED' || searchParams.get("status") === 'FAILED') {
      setShowErrorSubscriptionModal(true);
      analyticEvent('bkash-subscription', 'bkash-subscription-failed')
      activityLogSubmission("subscription_failed", localStorage.getItem('user_msisdn'));
    }
    searchParams.delete('status');
    setSearchParams(searchParams);
  }, [])
  useEffect(() => {
    if (!token) {
      setPlayerData({});
      let msisdn = null;
      popUpBannerFunction({});
      // activityLogSubmission("landing", null);
      // analyticEvent('home', 'home-view')

    } else {
      const config = {
        headers: { Authorization: `Token ${token}` },
      };
      if (!(playerData?.id)) {
        setOpeningLoader(true)
      }
      axios
        .get(
          `${baseUrl}/api/clients/participant_profiles/?portal=${portal}${(checkSuccessfulSubscription) ? `&status=SUCCEEDED&reference=${searchParams.get("reference")}` : ''}`,
          config
        )
        .then((response) => {
          popUpBannerFunction(response.data[0])
          setOpeningLoader(false)
          if (checkSuccessfulSubscription) {
            if (sessionStorage.getItem('cid') == 19) {
              callFloodlightTag()
              firebaseAnalytics.logEvent('begin_checkout', {
                reference: searchParams.get("reference") || ""
              });
            }
          }
          searchParams.delete('status')
          setSearchParams(searchParams)
          setPlayerData(response.data[0]);

          if (sessionStorage.getItem("login_event_id")) {
            quizClickHandlerInLogin(parseInt(sessionStorage.getItem("login_event_id")), response.data[0])
            sessionStorage.removeItem("login_event_id")
          }
          // console.log(response.data[0]);
          // activityLogSubmission("landing", response.data[0]?.msisdn);
          let data = response.data[0];
          // console.log(data);

          if (!data?.isSubscribe && (!checkFailedSubscription)) {

          }
        })
        .catch(res => {
          popUpBannerFunction({})
          setOpeningLoader(false)
          // console.log(res.response.status === 401)
          localStorage.setItem("AuthToken", "");
          localStorage.setItem("user_msisdn", "");
          setPlayerData({});
          // setShowLoginModal(true);
          setToken(null);
        });
    }
  }, [token, activeBoard, toggleToUpdate]);
  let backgroundImg;
  activeBottom === "leader"
    ? (backgroundImg = homeImg)
    : (backgroundImg = homeImg);
  // const [showProfile, setShowProfile] = useState(false);
  // (playerData?.id)? setShowProfile(true):setShowProfile(false)
  async function quizClickHandlerInLogin(eventId, playerData) {
    console.log(playerData)
    if (!(playerData?.id)) {
      sessionStorage.setItem("login_event_id", eventId);
      setShowLoginModal(true);
    } else if (categoryEventId.includes(eventId)) {
      // Category-International, Category-Sports, Category-Science and Technology, Category-General Knowledge, Category-Bangladesh
      // if (eventId === 42) { clevertap.event.push("Category-International"); }
      // if (eventId === 43) { clevertap.event.push("Category-Sports"); }
      // if (eventId === 44) { clevertap.event.push("Category-Bangladesh"); }
      // if (eventId === 46) { clevertap.event.push("Category-General Knowledge"); }
      // if (eventId === 76) { clevertap.event.push("Category-Science and Technology"); }
      navigate(`/quiz/?eventId=${eventId}`, { replace: true });
    }
    // eventid 34 daily event
    else if (eventId === 34) {
      if (playerData?.isSubscribe) {
        navigate(`/quiz/?eventId=${eventId}`, { replace: true });
      } else {
        if (true) {
          setShowLoadingModal(true)
          const config = {
            headers: { Authorization: `Token ${token}` },
          };
          let searchParamsQuizard = new URLSearchParams(window.location.search);
          let paramsObjectQuizard = Object.fromEntries(searchParamsQuizard);
          console.log(paramsObjectQuizard)
          axios
            .post(
              `${baseUrl}/subscription/create/`,
              { portal: portal, qcid: sessionStorage.getItem("cid"), "service_type": "Daily", ...paramsObjectQuizard },
              config
            )
            .then((response) => {
              let data = response.data;
              setShowLoadingModal(false)
              // console.log(data);
              if (data?.redirectURL) {
                // analyticEvent('redirect', 'redirect-bkash-payment')
                console.log(`${data?.redirectURL}`);
                // window.location.replace(`${data?.redirectURL}`);
                window.location.replace(`${data?.redirectURL}`);
                // return data?.redirectURL
              }
            });
        } else {
          sessionStorage.setItem('clicked_on', 'Daily');
          setShowRedeemModal(true)
        }
      }
    } else {

      if (playerData?.isSubscribeTournament) {
        navigate(`/quiz/?eventId=${eventId}`, { replace: true });
      } else {
        if (true) {
          setShowLoadingModal(true)
          const config = {
            headers: { Authorization: `Token ${token}` },
          };
          let searchParamsQuizard = new URLSearchParams(window.location.search);
          let paramsObjectQuizard = Object.fromEntries(searchParamsQuizard);
          console.log(paramsObjectQuizard)
          axios
            .post(
              `${baseUrl}/subscription/create/`,
              { portal: portal, qcid: sessionStorage.getItem("cid"), "service_type": "Tournament", ...paramsObjectQuizard },
              config
            )
            .then((response) => {
              let data = response.data;
              setShowLoadingModal(false)
              // console.log(data);
              if (data?.redirectURL) {
                // analyticEvent('redirect', 'redirect-bkash-payment')
                console.log(`${data?.redirectURL}`);
                // window.location.replace(`${data?.redirectURL}`);
                window.location.replace(`${data?.redirectURL}`);
                // return data?.redirectURL
              }
            });
        } else {
          sessionStorage.setItem('clicked_on', 'Tournament');
          setShowRedeemModal(true)
        }
      }
    }
  }

  return (
    <div
      className={styles.homePageBody} ref={homePageBodyRef}
      style={{ background: '#f5f5f5' }}
    >
      <div className={styles.extraHiddenFile}>বাংলাদেশের শীর্ষ ও নব্য কুইজ গেম QUIZARD ! সাম্প্রতিক ঘটনা সহ বিবিধ ঘটনার উপর কুইজ খেলুন, শিখুন, আর প্রতিদিন জিতু</div>
      {showOpeningBanner && <div className={styles.initialBanner}>
        <div className={styles.closeBtn} onClick={hideOpeningBanner}>X</div>
        <img src={'https://ms.purplepatch.online/bkash-quiz-banner/welcome-banner/popup.jpg'} alt="" srcset="" className={styles.openingBannerImg} onClick={() => { hideOpeningBanner(); popUpBannerClicked(); }} />
      </div>}
      <Sidebar
        props={{
          showSidebar,
          setShowSidebar,
          setShowLoginModal,
          showLoginModal,
          activeBottom,
          setActiveBottom,
          showContactModal,
          setShowContactModal,
          showTCModal,
          setShowTCModal,
          showReferralPolicyModal,
          setShowReferralPolicyModal,
          showRefundModal,
          setShowRefundModal,
          showRewardsModal,
          setShowRewardsModal,
        }}
      ></Sidebar>
      <div className={styles.fixedNavbar}>
        <Navbar
          showSidebar={showSidebar}
          setShowSidebar={setShowSidebar}
        />
      </div>
      <div className={styles.scrollableContent} ref={homePageBodyRef}>
        {activeBottom === "home" && (
          <BannerPart3
            props={{ quizClickHandler: quizClickHandlerInLogin, setShowLoginModal, showLoginModal, setShowShobDekho, setShowLoadingModal, showLoadingModal, showRedeemModal, setShowRedeemModal, setShowReferModal, setActiveBottom, homePageBodyRef }}
          ></BannerPart3>
        )}
        {activeBottom === "referral" && (
          <Referral
            props={{ setShowLoginModal, showLoginModal, setShowLoadingModal, showLoadingModal, showRedeemModal, setShowRedeemModal, setShowReferModal, setActiveBottom }}
          ></Referral>
        )}
        {activeBottom === "category" && (
          <Category
            events={events} quizClickHandler={quizClickHandlerInLogin} setShowShobDekho={setShowShobDekho}
          ></Category>
        )}
        {(activeBottom === "winner") && (
          <LeaderWinnerList
            showLoginModal={showLoginModal}
            setShowLoginModal={setShowLoginModal}
            activeBoard={activeBoard} setActiveBoard={setActiveBoard} setToggleToUpdate={setToggleToUpdate} toggleToUpdate={toggleToUpdate}
          ></LeaderWinnerList>
        )}
        {(activeBottom === "leader") && (
          <Leaderboard
            showLoginModal={showLoginModal}
            setShowLoginModal={setShowLoginModal}
            activeBoard={activeBoard} setActiveBoard={setActiveBoard} setToggleToUpdate={setToggleToUpdate} toggleToUpdate={toggleToUpdate}
          ></Leaderboard>
        )}
        {activeBottom === "profile" && (
          <Profile
            showLoginModal={showLoginModal}
            setShowLoginModal={setShowLoginModal}
            setShowReferModal={setShowReferModal}
            setActiveBottom={setActiveBottom}
            quizClickHandler={quizClickHandlerInLogin}
          ></Profile>
        )}
        {activeBottom === "subscription" && (
          <Subscription
            quizClickHandler={quizClickHandlerInLogin}
            setShowLoginModal={setShowLoginModal}
          ></Subscription>
        )}
        {activeBottom === 'rewards' && (
          <RewardsModal
            showRewardsModal={showRewardsModal}
            setShowRewardsModal={setShowRewardsModal}
          ></RewardsModal>
        )}
        {activeBottom === 'help' && (
          <ContactModal
            showContactModal={showContactModal}
            setShowContactModal={setShowContactModal}
            setShowRefundModal={setShowRefundModal}
          ></ContactModal>
        )}
        {activeBottom === 'tc' && (
          <TCModal
            showTCModal={showTCModal}
            setShowTCModal={setShowTCModal}
            showRefundModal={showRefundModal}
            setShowRefundModal={setShowRefundModal}
          ></TCModal>
        )}
        {activeBottom === 'result' && (
          <ResultPage quizClickHandler={quizClickHandlerInLogin}
            setActiveBottom={setActiveBottom} setActiveBoard={setActiveBoard}></ResultPage>
        )}
      </div>
      <div className={styles.fixedBottombar}>
        <Bottombar
          activeBottom={activeBottom}
          setActiveBottom={setActiveBottom}
          setShowLoginModal={setShowLoginModal}
          activeLeaderPage={activeLeaderPage}
          setActiveLeaderPage={setActiveLeaderPage}
          setShowShobDekho={setShowShobDekho}
        />
      </div>
      {showLoginModal && (
        <LoginModal
          showLoginModal={showLoginModal}
          setShowLoginModal={setShowLoginModal}
          activeBottom={activeBottom}
          setActiveBottom={setActiveBottom}
          setShowOtpModal={setShowOtpModal}
        ></LoginModal>
      )}
      {showLoadingModal && (
        <LoadingModal
          showLoadingModal={showLoadingModal}
          setShowLoadingModal={setShowLoadingModal}
        ></LoadingModal>
      )}
      {showReferModal && (
        <ReferModal
          showReferModal={showReferModal}
          setShowReferModal={setShowReferModal}
        ></ReferModal>
      )}
      {showRedeemModal && (
        <RedeemModal
          showRedeemModal={showRedeemModal}
          setShowRedeemModal={setShowRedeemModal}
          setShowLoadingModal={setShowLoadingModal}
          setToggleToUpdate={setToggleToUpdate} toggleToUpdate={toggleToUpdate}
        ></RedeemModal>
      )}
      {showOtpModal && (
        <OtpModal
          showOtpModal={showOtpModal}
          setShowOtpModal={setShowOtpModal}
          activeBottom={activeBottom}
        ></OtpModal>
      )}
      {showSubscriptionModal && (
        <SubscriptionModal
          showSubscriptionModal={showSubscriptionModal}
          setShowSubscriptionModal={setShowSubscriptionModal}
          searchParams={searchParams}
          setSearchParams={setSearchParams}
        ></SubscriptionModal>
      )}
      {showErrorSubscriptionModal && (
        <ErrorSubscriptionModal
          showErrorSubscriptionModal={showErrorSubscriptionModal}
          setShowErrorSubscriptionModal={setShowErrorSubscriptionModal}
          searchParams={searchParams}
          setSearchParams={setSearchParams}
        ></ErrorSubscriptionModal>
      )}
      {showUnSubscriptionModal && (
        <UnSubscriptionModal
          showUnSubscriptionModal={showUnSubscriptionModal}
          setShowUnSubscriptionModal={setShowUnSubscriptionModal}
          searchParams={searchParams}
          setSearchParams={setSearchParams}
        ></UnSubscriptionModal>
      )}
      {showShobDekho && (
        <ShobDekho
          showShobDekho={showShobDekho}
          setShowShobDekho={setShowShobDekho}
          quizClickHandler={quizClickHandlerInLogin}
        ></ShobDekho>
      )}
      {showReferralPolicyModal && (
        <ReferralPolicyModal
          showReferralPolicyModal={showReferralPolicyModal}
          setShowReferralPolicyModal={setShowReferralPolicyModal}
        ></ReferralPolicyModal>
      )}
      {showRefundModal && (
        <RefundModal
          showRefundModal={showRefundModal}
          setShowRefundModal={setShowRefundModal}
          setToggleToUpdate={setToggleToUpdate} toggleToUpdate={toggleToUpdate} setShowUnSubscriptionModal={setShowUnSubscriptionModal}
        ></RefundModal>
      )}
    </div>
  );
};

export default HomePageV3;
