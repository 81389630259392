import axios from "axios";
import { baseUrl, portal } from "../App";

export const fetchSubscriptionUrl = async (token, service_type) => {
  const searchParams = new URLSearchParams(window.location.search);
  const paramsObject = Object.fromEntries(searchParams);

  const config = {
    headers: { Authorization: `Token ${token}` },
  };

  const res = await axios
    .post(
      `${baseUrl}/subscription/create/`,
      { portal: portal, qcid: sessionStorage.getItem("cid"), "service_type": service_type, ...paramsObject },
      config
    )
  return res.data;
}