import React from 'react';
import styles from './CircularTimer.module.css';

const CircularTimer = ({ progress }) => {
  const radius = 18; // For a 40px circle
  const circumference = 2 * Math.PI * radius;
  const strokeDashoffset = circumference - (progress / 100) * circumference;

  return (
    <div className={styles.timerContainer}>
      <svg width="40" height="40" viewBox="0 0 40 40">
        <circle
          className={styles.timerBackground}
          cx="20"
          cy="20"
          r={radius}
          fill="none"
          strokeWidth="4"
        />
        <circle
          className={styles.timerProgress}
          cx="20"
          cy="20"
          r={radius}
          fill="none"
          strokeWidth="4"
          style={{
            strokeDasharray: circumference,
            strokeDashoffset: strokeDashoffset
          }}
          transform="rotate(-90 20 20)"
        />
      </svg>
    </div>
  );
};

export default CircularTimer; 