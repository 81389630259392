import React from 'react'
import styles from './Navbar.module.css';
import menuImg from './assets/hambard.png'
import logoImg from './assets/logo.png'
import bellImg from './assets/BellSimple.svg'
const Navbar = ({ setShowSidebar }) => {

  return (<>
    <div className={styles.ppm_navbar}>
      <img src={menuImg} className={styles.toggleBtn} onClick={() => { setShowSidebar(true) }} alt="" srcSet="" />
      <img src={logoImg} className={styles.ppm_logo} alt="" srcSet="" />
      <img src={bellImg} className={styles.bell} alt="" srcSet="" style={{opacity: 0}} />
    </div>
  </>
  )
}

export default Navbar