import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { dataContext } from "../../App";
import newLandingImg from "./assets/campLanding.jpg";
import newLandingImg2 from "./assets/campLanding2.jpg";
import LoginModalCampaign from "../../components/LoginModalCampaign/LoginModalCampaign";
import activityLogSubmission from "../../helper/activitylog";
import styles from "./Campaign.module.css";
import OtpModal from "../../components/Modal/OtpModal/OtpModal";
import LoginDivOnlyDaily from "../../components/LoginDivOnly/LoginDivOnlyDaily";
const Campaign = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeBottom, setActiveBottom] = useState("campaign");
  const [showLoginModalCampaign, setShowLoginModalCampaign] = useState(false);
  const [showOtpModal, setShowOtpModal] = useState(false);
  const cid = searchParams.get("qcid");
  const {playerData} = useContext(dataContext);
  let navigate = useNavigate();
  if (!cid) {
    navigate("../");
  }
  useEffect(() => {
    let campaignId = null;
    if(searchParams.get('qcid')){
      sessionStorage.setItem('cid', searchParams.get('qcid'));
      campaignId = searchParams.get('qcid');
    }

    setTimeout(() => {
      if(campaignId !== '19'){setShowLoginModalCampaign(true);}
      // just checking commit 
    }, 2000);
    let msisdn = null;
    if (playerData?.id) {
      msisdn = playerData?.msisdn;
    }
    activityLogSubmission("campaign", msisdn);
    activityLogSubmission("campaign1", msisdn);
  }, []);

  return (
    <>
      <div className={`${styles.landingPage}`}>
        <img src={`https://ms.purplepatch.online/bkash-quiz-banner/camp-banner/camp1.jpg`} alt="" srcSet="" className={styles.landingImg} onClick={()=>{
          setShowLoginModalCampaign(true)
        }} />
        {showLoginModalCampaign &&(<LoginDivOnlyDaily
          showLoginModalCampaign={showLoginModalCampaign}
          setShowLoginModalCampaign={setShowLoginModalCampaign}
          activeBottom={activeBottom}
          setActiveBottom={setActiveBottom}
          setShowOtpModal={setShowOtpModal}
          showOtpModal={showOtpModal}
        ></LoginDivOnlyDaily>)}
        {showOtpModal && (
        <OtpModal
          showOtpModal={showOtpModal}
          setShowOtpModal={setShowOtpModal}
          activeBottom={activeBottom}
        ></OtpModal>
      )}
                      <button 
          className={styles.tcButton} 
          onClick={() => navigate(`../?page=tc&qcid=${cid}`)}
        >
          Terms & Conditions
        </button>
      </div>
    </>
  );
};

export default Campaign;
