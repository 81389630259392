import React, { useContext, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal';
import './RewardsModal.module.css'
import Accordion from 'react-bootstrap/Accordion';
import { dataContext } from '../../../App';
import activityLogSubmission from '../../../helper/activitylog';
import analyticEvent from '../../../helper/gaEvent';
import { pushCleverTapEvent } from '../../../helper/clevertapFunction';
import styles from './RewardsModal.module.css'
import './RewardsModal.css'

const RewardsModal = ({ showRewardsModal, setShowRewardsModal }) => {
  const { playerData } = useContext(dataContext);

  function hideModalOperation() {
    setShowRewardsModal(false)
  }

  useEffect(() => {
    let msisdn = null;
    if (playerData?.id) {
      msisdn = playerData?.msisdn;
    }
    activityLogSubmission("rewards", msisdn);
    analyticEvent('rewards', 'rewards-view')
    pushCleverTapEvent('CheckIn-Prize');
  }, [])

  return (
    <>
      <div className={styles.rewardsModal}>
        <div className={styles.rewardsModalHeader}>
          <img src={require('./rewards.png')} className={styles.rewardsModalIcon} alt="medal" />
          <span className={styles.rewardsModalTitle}>প্রাইজের তালিকা</span>
        </div>
        <Accordion className={styles.accordion}>
          <Accordion.Item eventKey="0" className={styles.accordionItem}>
            <Accordion.Header className={styles.accordionButton}>কুইজ প্রতিদিনের প্রাইজ</Accordion.Header>
            <Accordion.Body className={styles.accordionBody}>
              কুইজ প্রতিদিনে অংশগ্রহণকারীদের মধ্যে টপ ৩০ জন স্কোরার পাবেন ২০ টাকা ক্যাশ ইন প্রাইজ।
              <br />
              <br />
              **প্রতি সপ্তাহের রবিবার প্রাইজ দেয়া হয়ে থাকে।
              <br />
              <b>**প্রতি সপ্তাহে ১ জন প্লেয়ার কুইজ প্রতিদিনে সর্বোচ্চ ৬০ টাকা পর্যন্ত জিতে নিতে পারবেন</b>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="1" className={styles.accordionItem}>
            <Accordion.Header className={styles.accordionButton}>সাপ্তাহিক কুইজ প্রাইজ</Accordion.Header>
            <Accordion.Body className={styles.accordionBody}>
              প্রতি সপ্তাহের স্পোর্টস টুর্নামেন্ট অংশগ্রহণকারীদের মধ্যে সেরা ১০  সঠিক উত্তর প্রদানকারী কে ১০০০ টাকা পর্যন্ত বিকাশ ক্যাশ ইন করা হবে।
              <br />
              <br />
              **প্রতি সপ্তাহের রবিবার প্রাইজ দেয়া হয়ে থাকে।
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="2" className={styles.accordionItem}>
            <Accordion.Header className={styles.accordionButton}>টুর্নামেন্ট প্রাইজ</Accordion.Header>
            <Accordion.Body className={styles.accordionBody}>
              প্রথম ২ জন টপ স্কোরার পাবেন টুর্নামেন্ট মেগা প্রাইজ-
              <ul>
                <li>১ম পুরস্কার: কাপল প্যাকেজ ১দিন ২রাত ঢাকা টু কক্সবাজার ট্যুর</li>
                <li>২য় পুরস্কার: ৫ স্টার হোটেল কাপল বাফেট ডিনার</li>
              </ul>
              ** টুর্নামেন্ট শেষে বিজয়ীদের সাথে যোগাযোগ করে প্রাইজ দেয়া হয়ে থাকে।
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3" className={styles.accordionItem}>
            <Accordion.Header className={styles.accordionButton}>১০ টাকা ক্যাশ ব্যাক প্রাইজ</Accordion.Header>
            <Accordion.Body className={styles.accordionBody}>
              Quizard এ ডেইলি কুইজ এবং টুর্নামেন্ট কুইজ উভয় প্যাকেজেই যারা পেইড সাবস্ক্রাইবড আছেন তারা প্রতি সপ্তাহে ১০ টাকা ক্যাশ ব্যাক পাবেন।
              <br />
              <br />
              **প্রতি সপ্তাহের রবিবার প্রাইজ দেয়া হয়ে থাকে।
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="4" className={styles.accordionItem}>
            <Accordion.Header className={styles.accordionButton}>১০ টাকা ক্যাশ ইন প্রাইজ</Accordion.Header>
            <Accordion.Body className={styles.accordionBody}>
              আপনার রেফার কোড ব্যাবহার করে কেউ যদি সাবস্ক্রিপশন করে, তাহলে প্রতি সফল সাবস্ক্রিপশনে আপনি পেয়ে যাবেন ১০ টাকা ক্যাশ ইন। রেফারি এবং রেফারাল ইউজার উভয়ই ১০ টাকা ক্যাশ ইন পাবেন
              <br />
              <br />
              **প্রতি সপ্তাহের রবিবার প্রাইজ দেয়া হয়ে থাকে।
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </>
  )
}

export default RewardsModal