// import logo from './logo.svg';
import "./App.css";
import { createBrowserRouter, RouterProvider, useSearchParams } from "react-router-dom";
import { createContext, useEffect, useState } from "react";
import axios from "axios";
import QuizPage from "./pages/QuizPage/QuizPage";
import activityLogSubmission from "./helper/activitylog";
import Campaign from "./pages/Campaign/Campaign";
import ReactGA from "react-ga4";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import HomePageV3 from "./pages/HomePageV3/HomePageV3";
import Campaign2 from "./pages/Campaign2/Campaign2";
import Campaign3 from "./pages/campaign3/Campaign3";
import Campaign4 from "./pages/campaign4/Campaign4";
import clevertap from "clevertap-web-sdk";
import OpeningLoader from "./components/OpeningLoader/OpeningLoader";
import { pushCleverTapEvent } from "./helper/clevertapFunction";
import Campaign5 from "./pages/campaign5/Campaign5";
export const baseUrl =  `https://cms.quizard.live`;
export const portal = 15;
export const categoryEventId = [42,43,44,46,76,96,97];

export const dataContext = createContext();
const firebaseConfig = {
  apiKey: "AIzaSyBLB8mg0DfRjP4QOx-nS6TRRhtlK0VV29s",
  authDomain: "quizard-cpa.firebaseapp.com",
  projectId: "quizard-cpa",
  storageBucket: "quizard-cpa.appspot.com",
  messagingSenderId: "305352260036",
  appId: "1:305352260036:web:c8dce84b75b6d09268cc91",
  measurementId: "G-VWCLVZC2QB"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const firebaseAnalytics = getAnalytics(app);

const router = createBrowserRouter([
  {
    path: "/rolling",
    element: <HomePageV3 />,
  },
  {
    path: "/",
    element: <HomePageV3 />,
  },
  {
    path: "/campaign/",
    element: <Campaign />,
  },
  {
    path: "/campaign2/",
    element: <Campaign2 />,
  },
  {
    path: "/campaign3/",
    element: <Campaign3 />,
  },
  {
    path: "/campaign4/",
    element: <Campaign4 />,
  },
  {
    path: "/campaign5/",
    element: <Campaign5 />,
  },
  {
    path: "/quiz/",
    element: <QuizPage></QuizPage>,
  },
]);
function App() {
  const url = new URL(window.location.href);
  let urlTokenParam = url.searchParams.get("auth_token_url");
  url.searchParams.delete('auth_token_url');
  let referCode = url.searchParams.get("refer_code");
  if(referCode){sessionStorage.setItem('refer_code',referCode)}
  let promoCode = url.searchParams.get("promo_code");
  if(promoCode){sessionStorage.setItem('promo_code',promoCode)}
  window.history.pushState({}, '', url.href);
  let localToken = localStorage.getItem("AuthToken") || urlTokenParam || "";

  const [token, setToken] = useState(localToken);
  const [playerData, setPlayerData] = useState({});
  const [events, setEvents] = useState([]);
  const [openingLoader, setOpeningLoader] = useState(false);
  const [extraData, setExtraData] = useState([]);
  useEffect(() => {
    axios.get(`${baseUrl}/api/rules/?portal=${portal}`).then((response) => {
      setEvents(response.data);
    });
    document.addEventListener('contextmenu', (e) => {
      e.preventDefault();
    });
    document.addEventListener('keydown', event => {
      if ((event.ctrlKey || event.metaKey) && (event.shiftKey) && (event.key === 'I' || event.key === 'J') || event.keyCode === 123) {
        event.preventDefault();
      }
    });
    activityLogSubmission("landing", localStorage.getItem('user_msisdn') || null);
    if(sessionStorage.getItem('initial_load')!=1){
      sessionStorage.setItem('initial_load', 1);
      pushCleverTapEvent("Unique Home View");
    }
  }, []);

  ReactGA.initialize([
    {
      trackingId: "G-CRSTC3TC4D",
    },
    {
      trackingId: "G-4PB7ZL0RMX",
    },
  ]);

  useEffect(()=>{
    // clevertap.notifications.push({
    //   // "apnsWebPushId":"<apple web push id>", //only for safari browser
    //   // "apnsWebPushServiceUrl":"<safari package service url>",//only for safari browser
    //   "titleText":'QUIZARD এর সাথেই থাকুন!!',
    //   "bodyText":'QUIZARD এর Latest Updates, নতুন নতুন ফিচার্স, অফার এবং আকর্ষনীয় সব পুরষ্কার সম্বন্ধে জানতে আমাদের সাথে থাকুন।',
    //   "okButtonText":'জানতে চাই',
    //   "rejectButtonText":'আগ্রহী নই',
    //   "okButtonColor":'#178a00',
    //   "serviceWorkerPath": "/service-worker.js"
    // })
    console.log('clevertap paused!!')
  },[])
  return (
    <div className="App">
      <div className="ppm_quiz_body">
      {openingLoader && <OpeningLoader />}
        <dataContext.Provider
          value={{
            token,
            setToken,
            playerData,
            setPlayerData,
            events,
            setEvents,
            portal,
            setOpeningLoader,
            extraData, setExtraData
          }}
        >
          <RouterProvider router={router} />
        </dataContext.Provider>
      </div>
    </div>
  );
}

export default App;
