import React, { useContext } from 'react'
import styles from './categoryBox.module.css'
import scienceImg from './assets/science.png'
import sportsImg from './assets/sports.png'
import exploreImg from './assets/explore.png'
import internationalImg from './assets/international.png'
import bangladeshImg from './assets/bangladesh.png'
import generalImg from './assets/general.png'
import bcsImg from './assets/bcs.png'
import bankImg from './assets/bank.png'
import { dataContext } from '../../../App'

const CategoryBox = ({ props }) => {
  const { playerData } = useContext(dataContext);
  const { events, quizClickHandler } = props;
  const randomEvents = [41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52];
  
  return (
    <div className={styles.categoryBox}>
      <h2 className={styles.title}>কুইজ ক্যাটাগরি</h2>
      <div className={styles.container}>
        <div className={styles.categoryItem} onClick={() => quizClickHandler(76,playerData)}>
          <div className={styles.iconWrapper}>
            <img src={scienceImg} alt="science" />
          </div>
          <span>বিজ্ঞান ও প্রযুক্তি</span>
        </div>

        <div className={styles.categoryItem} onClick={() => quizClickHandler(43,playerData)}>
          <div className={styles.iconWrapper}>
            <img src={sportsImg} alt="sports" />
          </div>
          <span>খেলাধুলা</span>
        </div>

        <div className={styles.categoryItem} onClick={() => quizClickHandler(42,playerData)}>
          <div className={styles.iconWrapper}>
            <img src={internationalImg} alt="international" />
          </div>
          <span>আন্তর্জাতিক</span>
        </div>

        <div className={styles.categoryItem} onClick={() => quizClickHandler(44,playerData)}>
          <div className={styles.iconWrapper}>
            <img src={bangladeshImg} alt="bangladesh" />
          </div>
          <span>বাংলাদেশ</span>
        </div>

        <div className={styles.categoryItem} onClick={() => quizClickHandler(46,playerData)}>
          <div className={styles.iconWrapper}>
            <img src={generalImg} alt="general" />
          </div>
          <span>সাধারণ জ্ঞান</span>
        </div>

        <div className={styles.categoryItem} onClick={() => quizClickHandler(96,playerData)}>
          <div className={styles.iconWrapper}>
            <img src={bcsImg} alt="bcs" />
          </div>
          <span>BCS প্রস্তুতি</span>
        </div>

        <div className={styles.categoryItem} onClick={() => quizClickHandler(97,playerData)}>
          <div className={styles.iconWrapper}>
            <img src={bankImg} alt="bank" />
          </div>
          <span>ব্যাংক চাকরি প্রস্তুতি</span>
        </div>

        <div className={styles.categoryItem} onClick={() => quizClickHandler(46,playerData)}>
          <div className={styles.iconWrapper}>
            <img src={exploreImg} alt="explore" />
          </div>
          <span>এক্সপ্লোর</span>
        </div>
      </div>
    </div>
  );
};

export default CategoryBox;